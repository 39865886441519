@mixin query-list-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font-accent: mat-color($custom-colors, font-accent);
  $background: mat-color($custom-colors, background-color);
  $background-color2: mat-color($custom-colors, background-color2);
  $background-color3: mat-color($custom-colors, background-color3);
  $background-color5: mat-color($custom-colors, background-color5);

  $grey: mat-color($custom-colors, grey);
  $dark-accent: mat-color($custom-colors, dark-accent);
  $font4: mat-color($custom-colors, font4);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  // .even-item {
  //   background-color: $background-color2;
  // }

  // .odd-item {
  //   background-color: $background-color3 !important;
  // }

  .selected {
    background-color: $grey;
    border-left: 4px solid #314a46 !important;
  }

  .selected-geo4,
  .multipins {
    background-color: $background-color5 !important;
    .query-text {
      color: $primary;
      .telno,
      .hashtag {
        color: $primary;
      }
    }
  }

  .query-text {
    color: $font-accent;
  }

  .fab-btn {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #7c7b7b !important;
  }

  .fab-actions {
    .btn-actions {
      background-color: $background;
      color: #7c7b7b;
    }
  }

  .emergency {
    color: $font4;
  }
}
