@mixin profiler-feed-item-details-dialog-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-second-font: mat-color($custom-colors, profiler-second-font);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $background-color4: mat-color($custom-colors, background-color4);

  .feed-item-detail-view-dialog {
    .profiler-feed-item-header {
      display: inline-flex;
      position: relative;
      width: 100%;
    }

    .profiler-feed-item-text {
      padding-left: 10px;
    }

    .feed-type-icon {
      height: 25px;
      position: absolute;
      right: 5px;
    }

    .profiler-feed-item-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
    }

    .profiler-feed-item-subtitle {
      font-size: 14px;
      vertical-align: text-top;
    }

    .close-btn {
      color: #a6a9b0;

      &:hover {
        color: #ccc;
      }
    }

    .link-description:hover {
      color: blue;
    }

    .icon-description:hover {
      color: blue;
    }

    .feed-item-detail-view-dialog-location-container {
      width: 90vw;

      .profiler-geolocation-details-dialog-content {
        .profiler-geolocation-details-dialog-section {
          color: $profiler-font;

          .profiler-geolocation-details-dialog-section-title {
            color: $profiler-second-font;
          }

          .profiler-geolocation-details-dialog-item {
            color: $profiler-font;
          }
        }
      }
    }

    .feed-item-detail-view-dialog-post-container {
      .post-was-added-text {
        color: $secondary;
      }

      .post-content {
        color: $profiler-second-font;
      }
    }

    .feed-item-detail-view-dialog-image-container {
      .photo-description {
        line-height: 1.29;
        color: $profiler-second-font;
        margin-bottom: 10px;
      }

      .tagged-list {
        text-decoration: none;
        color: $profiler-second-font;
      }

      .view-text {
        color: $profiler-accent;
      }

      .btn-analyse {
        border: solid 1px $profiler-accent;
        background-color: $profiler-accent;
        color: $background-color4;

        span {
          color: $background-color4 !important;
        }
      }
    }
  }

  .feed-item-details-view-dialog-title {
    color: #4a6da7;

    .album-details {
      color: $profiler-font;
    }
  }

  .feed-item-detail-view-notes-list-container {
    .feed-item-detail-view-note-container {
      .feed-item-detail-view-description {
        color: $profiler-second-font;
        border-left: 2px solid rgba(0, 0, 0, 0.38);
      }

      .feed-item-detail-view-date {
        color: #90949c;
      }
    }
  }

  .back-to-photo {
    color: $profiler-accent;
  }
}
