@mixin tasks-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $background: mat-color($custom-colors, background-color);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .tasks-table {
    table {
      background-color: $background !important;
      .mat-mdc-row {
        background-color: #faf6f3;
      }
    }
  }

  .tasks-mat-paginator {
    background-color: $background !important;
  }

  .tasks-close-btn {
    color: #d4e5f9;
  }

  .geo-tasks {
    table {
      border: solid 1px #ebe1dc;
    }

    th {
      background-color: #ebe1dc;
      color: #4a4a4a;
    }

    .mat-mdc-row {
      background-color: #faf6f3;
    }
  }

  .geo4-mobile-tasks {
    mat-sidenav {
      background-color: $primary;
    }

    mat-sidenav-content {
      background-color: $background;

      .tasks-table {
        table {
          .mat-mdc-table-sticky:first-child {
            border-right: 1px solid #e9e6e3;
          }

          .mat-mdc-table-sticky:last-child {
            border-left: 1px solid #e9e6e3;
          }

          .mat-mdc-header-cell,
          .mat-mdc-cell {
            color: #757575 !important;
          }
        }
      }
    }
  }
}
