@mixin bottom-sheet-mobile-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .bottom-sheet-open {
    background-color: $primary;
    .top-row {
      border-bottom: 2px solid #ececec;
    }
  }
}
