/*
Generate padding and margin helper class
Example : m-0, m-1, mt-10
*/

$space-amounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50);
$sides: (top, bottom, left, right);

@each $space in $space-amounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

/*
* Returns the value from `$space-amounts` list at the specified index
* Value returned is in `px` units.
*
 * To use this function, import the helpers file into your component like this:
 * @use 'src/styles/helpers' as *;
*
 * You can then call the function by specifying the desired index to get the corresponding value.
*
* Examples:
* space-amount(0) => 0px
* space-amount(1) => 5px
* space-amount(2) => 10px
* ...
* etc
*/
@function space-amount($index) {
  @return nth($space-amounts, $index + 1) + px;
}

.h-full {
  height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.w-90 {
  width: 90px !important;
}

.h-56 {
  height: 56px !important;
}

.font-gray {
  color: gray;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.bg-white {
  background: white !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.pointer-none {
  pointer-events: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-help {
  cursor: help;
}

.shadow,
.border-shadow {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.16);
}

.border-shadow {
  border-radius: 5px;
}

.error {
  color: red;
  margin: 5px 0;
  font-size: 14px;
  display: inline-block;
}

.flex-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-vert-center {
  display: flex;
  align-items: center;
}

.flex-hor-center {
  display: flex;
  justify-content: center;
}

.flex-no-grow {
  flex-grow: 0 !important;
}

// For Titles
.head-title {
  text-transform: capitalize;
  margin: 10px 0;
  font-weight: bold;
}

.title {
  font-size: 14px;
  color: #1d1d1d;
}
// End

.show-more {
  display: inline-block;
  color: #008df6;
  cursor: pointer;
}

.drop-arr {
  position: absolute;
  right: 0;
  top: 60%;
  transform: translateY(-50%) rotate(0);
  height: 10px;
  width: 10px;
  border: 7px solid transparent;
  border-top-color: #d8e1e4;
  cursor: pointer;
  transition: 0.5s;

  &.active {
    transform: translateY(-50%) rotate(180deg);
    top: 50%;
  }

  &:hover {
    border-top-color: #2878fa;
  }
}

.round-corner {
  border-radius: 5px;
  overflow: hidden;
}

.loading-wrp {
  width: 100%;
}

// For Tabs
.tab-head {
  text-transform: capitalize;
  padding: 10px 15px;
  background-color: #d8e1e4;
}

.tab-title {
  font-weight: bold;
  font-size: 14px;
  color: #1d1d1d;
}
// End

.bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.icon.mat-icon:not([svgIcon]) {
  font-size: 16px;
  height: initial;
  width: initial;
  margin-right: 3px;
}

.icon-grey {
  color: #aeaaaa;
}

/* Borders */
.border {
  border-width: 1px !important;
}

.border-0 {
  border-width: 0 !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-4 {
  border-width: 4px !important;
}

/* Border Radius */
.br-30 {
  border-radius: 30px !important;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 0.3s ease;
}

.credits,
button.cp-btn-primary.cp-search-btn .credits,
button.cp-btn-ghost.cp-search-btn .credits {
  font-size: 10px;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 3px;
  line-height: initial;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.pos-rlt {
  position: relative;
}

.word-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  display: inline-block;
}

[hidden],
.hidden {
  display: none !important;
}

.assistant-font-family {
  font-family: 'Assistant', sans-serif;
}

.assistant-font {
  font-family: 'Assistant', sans-serif;
  p,
  span {
    font-family: 'Assistant', sans-serif;
  }
}
.ubuntu-font {
  font-family: 'Ubuntu', sans-serif;
  p,
  span {
    font-family: 'Ubuntu', sans-serif;
  }
}

// Demo sign for UNLIMITED theme
.demo-sign {
  background-color: red;
  color: white;
  padding: 3px;
  position: absolute;
  border-radius: 4px;
  top: -9px;
  right: -11px;
  font-weight: bold;
  z-index: 10;
  line-height: 12px;
  font-size: 10px;
}

.grayed-details {
  color: gray;
}

.input-desc {
  color: #414242;
  opacity: 0.56;
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize-none {
  text-transform: none;
}

.expired-label {
  padding: 4px 15px;
  border-radius: 3px;
  background-color: #fdebeb;
  font-weight: normal;
  font-size: 12px;
  margin: 0;
}

.beta-sign-btn-label {
  font-size: 10px;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: -6px;
  background-color: red;
  padding: 0 3px;
  line-height: 15px;
  border-radius: 3px;
}

.beta-sign-label {
  font-size: 12px !important;
  color: red;
  font-weight: 700;
  position: absolute;
  right: 0px;
  top: 0px;
}

mat-icon.copy-icon {
  color: blue;
  font-size: 14px;
  height: initial;
  width: initial;
  cursor: pointer;
  margin-left: 5px;
}

intellectus-icon-text-button.copy-icon fe-platform-fa-pro-icon {
  color: blue;
  height: initial;
  width: initial;
  cursor: pointer;
  margin-left: 5px;
}

mat-hint {
  font-size: 14px;
  padding: 2px 0px;
}

.floating-msg {
  border-radius: 5px;
  padding: 5px 15px;
  max-width: 350px;
  position: absolute;
  background-color: #fff;
  top: 65px;
  right: -5px;
  box-shadow: 0 0 8px 3px #0000002e;
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(180%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
  p {
    font-family: Assistant;
    font-size: 14px;
    line-height: 1.13;
    color: rgba(4, 9, 48, 0.7);
    white-space: normal;
  }
}

.drag-drop-autocomplete {
  border: solid 1px #d7dade !important;
  max-height: 165px !important;
  box-shadow: none !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.timebar-container {
  width: 100vw;
  height: 106px;
}

.investigation-loader {
  position: absolute;
  z-index: 1000;
  bottom: 60px;
  padding: 12px 17px 11px 18px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.investigation-call-logs-slider {
  .mat-slider-wrapper {
    .mat-slider-track-fill {
      background-color: #396aff;
    }

    .mat-slider-track-background {
      background-color: #396aff;
      opacity: 0.5;
    }

    .mat-slider-thumb {
      background-color: #396aff !important;
      width: 13px;
      height: 13px;
      bottom: -7px;
    }
  }
}

.bottom-draw-otp {
  width: 100% !important;
  max-width: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  .footer {
    button {
      width: 100% !important;
    }
  }
}
