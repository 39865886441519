@mixin ledger-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background-color3: mat-color($custom-colors, background-color3);
  $grey: mat-color($custom-colors, grey);
  $profiler-second-font: mat-color($custom-colors, profiler-second-font);

  .ledger {
    background: transparent !important;

    tr.selected {
      background-color: $grey !important;
    }

    tr.selected :first-child {
      background-color: $grey !important;
      border-left: 4px solid #314a46 !important;
      border-radius: 4px !important;
    }
  }

  .no-data {
    color: $profiler-second-font;
  }
}
