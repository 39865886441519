@mixin statistics-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font-accent: mat-color($custom-colors, font-accent);
  $border: mat-color($custom-colors, border);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .geo-statistics-top-row {
    .stats-header {
      color: $font-accent;
    }
  }

  .geo-statistics-filters {
    border-top: 1px solid $border;
  }

  .mnos-tab-content {
    .col {
      background-color: $primary;
    }

    .headers {
      .chart-header {
        background-color: $primary;
        color: #363b4e;
      }

      .period-header {
        color: #adadad;
        border-left: 1px solid #adadad;
      }
    }

    .table-headers {
      .header {
        color: #232425;
      }
    }

    .table-entry {
      color: #232425;
      border-top: 1px solid $border;
    }

    .no-results {
      color: #363b4e;
    }
  }
}
