@mixin link-analysis-node-sidebar-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $person: #ff632f;
  $facebook-group: #2c4047;
  $organization: #016095;

  .la-node-details-container {
    border-top: solid 1px $profiler-secondary;
  }

  .la-node-person-image {
    border: 2px solid $person;
  }

  .la-node-group-image {
    border: 2px solid $facebook-group;
  }

  .la-node-organization-image {
    border: 2px solid $organization;
  }

  .la-node-detail-labels {
    color: $profiler-font;
  }

  .la-node-detail-values {
    .la-node-details-url {
      color: $profiler-accent;
    }
  }

  .la-node-details-analyse-results {
    border-top: 1px solid $profiler-secondary;
    border-bottom: 1px solid $profiler-secondary;
  }

  .la-node-details-btn-light {
    background-color: $primary;
    border: solid 1px $profiler-accent !important;
    span {
      color: $profiler-accent;
    }
  }

  .la-node-details-btn-fill {
    background-color: $profiler-accent;
    span {
      color: $primary;
    }
  }

  .redirect-to-target {
    color: $profiler-accent;
  }
}
