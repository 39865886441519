.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
      .mdc-tooltip__surface {
        background: #fff;
        border-radius: 4px;
        color: #000;
        box-shadow: 1px 1px 11px 2px rgb(0 0 13 / 15%);
        font-family: sans-serif;
        font-size: 12px;
        line-height: normal;
        padding: 8px;
        white-space: nowrap;
        max-width: 1000px;
      }
    }
  }
}
