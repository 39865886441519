@mixin users-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .user-table {
    .lock {
      color: $accent;
    }
  }

  .user-actions {
    .add-user {
      background-color: $accent;
      color: $primary;
    }
  }
}
