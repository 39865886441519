button {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  appearance: none;
  border-radius: 3px;
  padding: 8px 15px;
  border: none;
  text-transform: none;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  color: #414242;
  &.intel-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
    span {
      &.btn-txt {
        color: #fff;
        padding: 0 10px;
        background-color: #2e7cff;
        display: inline-block;
        border: 1px solid #2f7dff;
        border-radius: 5px 0 0 5px;
        position: relative;
        text-transform: capitalize;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 30px;
          right: -41px;
          border-left: 35px solid #2f7dff;
          border-right: 35px solid transparent;
          border-top: 10px solid transparent;
          transform: rotate(90deg);
        }
      }
      &.credits {
        color: #2e7cff;
        background-color: white;
        display: inline-flex;
        align-items: center;
        border-left: none;
        border: 1px solid #2f7dff;
        border-radius: 0 5px 5px 0;
        padding: 0 10px;
        width: initial;
        height: initial;
        line-height: unset;
        img {
          margin-right: 5px;
        }
      }
    }
  }
  &.btn-cancel {
    border: 1px solid #d8e1e4;
  }

  &.btn-border {
    border: 1px solid #2878fa;
    color: #2878fa;
  }

  &.btn-confirm {
    color: #fff;
    background-color: #2878fa;
  }

  &.btn-green {
    background-color: #3f5c58;
    color: white;
  }

  &.btn-clean {
    padding: initial;
    background-color: transparent;
  }

  &.btn-white {
    border: 1px solid #d8e1e4;
    background: #fff;
    &:hover {
      background-color: #2b79fa;
      color: #fff;
    }
  }

  &.btn-green {
    color: #fff;
    span.mat-button-wrapper {
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  &.btn-blue {
    color: #2878fa;
  }

  // TODO: later move the "border-radius & padding" property from all buttons to main "button"
  // For CP Starts
  &.cp-btn-primary {
    border-radius: 20px;
    padding: 0 25px;
    background-color: $brightBlue;
    color: #fff;
    transition: 0.3s;
    &:hover {
      background-color: $darkBlue;
    }
    &.cp-search-btn {
      padding: 0;
      background-color: #cdced6;
      span.mat-button-wrapper {
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
      span {
        display: inline-block;
        margin: 0px 5px;
        color: #fff;
      }
      mat-icon {
        border-left: 1px solid #ffffff59;
        width: 35px;
      }
      &.active {
        pointer-events: initial;
        background-color: #396aff;
      }
    }
    &.btn-stroke {
      background: none;
      border: 1px solid $brightBlue;
      color: $brightBlue;
    }
  }

  &.cp-btn-ghost {
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid $brightBlue;
    color: $brightBlue;
    &.cp-search-btn {
      padding: 0;
      background-color: #fff;
      span.mat-button-wrapper {
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
      span {
        display: inline-block;
        margin: 0px 5px;
        color: $brightBlue;
        &.credits {
          border: 2px solid $brightBlue;
        }
      }
      mat-icon {
        border-left: 1px solid #ffffff59;
        width: 35px;
      }
      &.active {
        pointer-events: initial;
        background-color: #fff;
      }
    }
  }

  &.cp-btn-clear {
    border-radius: 20px;
    color: $brightBlue;
    text-decoration: underline;
  }

  &.cp-btn-dropdown {
    border-radius: 20px;
    background-color: $lightGray;
    color: #636363;
  }

  &.mat-button[disabled],
  &.mat-mdc-menu-item[disabled],
  .disabled {
    background-color: #ceced6;
    pointer-events: none;
    border-color: #e6e6ea;
  }
  &.mat-button-disabled {
    opacity: 0.5;
  }
  // For CP Ends
}
button.mat-mdc-menu-item {
  text-transform: none;
}
.btn-default {
  border: solid 1px #d3d3d3 !important;
  color: #414242;
}

.btn-primary {
  background-color: #008df6;
  color: white;
}

.mat-icon.btn-spinner {
  position: relative;
  height: 15px;
  padding-left: 10px;
}

.btn-icon {
  padding: 0 !important;
  background: transparent !important;
  border-color: transparent !important;
}

.btn-wrp {
  button {
    margin: 0 10px;
  }
}
