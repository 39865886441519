@mixin ic-log-table-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background: mat-color($custom-colors, background-color);
  $font-accent: mat-color($custom-colors, font-accent);
  $dark-accent: mat-color($custom-colors, dark-accent);
  $background-color5: mat-color($custom-colors, background-color5);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .imsi-catcher-item-even {
    background-color: #d8e1e4;
  }

  .imsi-catcher-item-odd {
    background-color: $background;
  }

  .imsi-catcher-item {
    color: $font-accent;
  }

  .selected-tactical-item {
    background-color: $background-color5 !important;
    color: $primary !important;
    .column {
      color: $primary;
      span {
        color: $primary;
      }
    }
  }
}
