@mixin select-location-dialog-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);

  .select-location-dialog-actions {
    .select-location-dialog-subtitle {
      color: $profiler-font;
    }

    .select-location-dialog-button {
      background-color: $primary;
      border: solid 2px #d3d3d3;
      color: rgba(139, 139, 139, 0.84);
    }

    .select-location-dialog-radio-buttons {
      .select-location-dialog-radio-button {
        color: $profiler-font;
      }
    }
  }
}
