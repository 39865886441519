@mixin profiler-check-in-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  .profiler-check-in {
    background-color: $primary;
    .relation-titles {
      color: #3c4144;
    }
    .image-name {
      color: #3c4144;
    }
  }
}
