@mixin area-info-window-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background: mat-color($custom-colors, background-color2);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .border {
    background-color: $primary;

    .container {
      .labels {
        .label {
          color: #414242;
        }
      }

      .values {
        .value {
          color: #414242;
        }

        .value-edit {
          border: solid 1px $background;
          background-color: #fcfcfc;
        }
      }
    }

    .actions {
      border-top: solid 1px rgba(216, 225, 228, 0.32);

      .cancel-btn {
        color: rgba(65, 66, 66, 0.74);
      }

      .accent-btn {
        color: #008df6;
      }
    }
  }
}
