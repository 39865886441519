@mixin day-time-selector-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $grey: mat-color($custom-colors, grey);

  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .day-time-selector-container {
    ::ng-deep .mat-mdc-progress-bar {
      background-color: $accent;
    }

    ::ng-deep .mdc-linear-progress__primary-bar {
      background: $grey;
    }
  }
}
