@import 'palette';
:root {
  //Buttons
  --i9s-btn-primary-bg: #{$bright-blue};
  --i9s-btn-primary-color: #{$white};
  --i9s-btn-primary-hover-bg: #{$bright-blue-50};
  --i9s-btn-primary-active-bg: #{$bright-blue-40};
  --i9s-btn-primary-focus-bs: #396aff;
  --i9s-btn-primary-disabled-bg: #{$bright-blue-90};

  --i9s-btn-secondary-color: #{$bright-blue};
  --i9s-btn-secondary-hover-bg: #{$bright-blue-50};
  --i9s-btn-secondary-hover-color: #{$white};
  --i9s-btn-secondary-active-bg: #{$bright-blue-40};
  --i9s-btn-secondary-focus-bs: #396aff;
  --i9s-btn-secondary-disabled-color: #{$bright-blue-90};

  --i9s-btn-primary-alt-bg: #{$white};
  --i9s-btn-primary-alt-color: #3d3d3d;
  --i9s-btn-primary-alt-hover-bg: #{$white-70};
  --i9s-btn-primary-alt-active-bg: #{$white-50};
  --i9s-btn-primary-alt-focus-bs: #{$white-40};
  --i9s-btn-primary-alt-disabled-bg: #{$white-20};

  --i9s-btn-secondary-alt-color: #{$white};
  --i9s-btn-secondary-alt-hover-bg: #{$white-70};
  --i9s-btn-secondary-alt-hover-color: #3d3d3d;
  --i9s-btn-secondary-alt-active-bg: #{$white-50};
  --i9s-btn-secondary-alt-focus-bs: #{$white-40};
  --i9s-btn-secondary-alt-disabled-color: #{$white-20};

  --i9s-btn-secondary-danger-color: #{$red-80};
  --i9s-btn-secondary-danger-hover-bg: #{$red-100};
  --i9s-btn-secondary-danger-hover-color: #{$white};
  --i9s-btn-secondary-danger-active-bg: #{$red-40};
  --i9s-btn-secondary-danger-focus-bs: #{$red-90};
  --i9s-btn-secondary-danger-disabled-color: #{$white-20};

  --navbar-height: 71px;
  --color-primary: #396aff;
  --color-primary2: #3365ff;
  --color-muted: #a7a7a7;
  --border-color: #dedede;
  --divider-color: #d2d2d2;
  --gmap-custom-btn-color: #666666;

  --widget-background: #fafafa;
  --widget-border-color: #ebebeb;

  --error-background: #{$red-10};
  --error-color: #{$red-100};

  --success-background: #{$green-10};
  --success-color: #{$green-100};

  --warning-background: #{$orange-10};
  --warning-color: #{$orange-100};

  --navbar-bg-color: #{$dark-blue-45};

  --c1-color: #1fd0bf;
  --c2-color: #eb648b;
  --c3-color: #f8c753;
  --c4-color: #8a31c4;
  --c5-color: #d5001a;
  --c6-color: #a93790;
  --c7-color: #42b3d5;
  --c8-color: #049587;
  --c9-color: #1553b6;
  --c10-color: #eb7e30;
  --c11-color: #8b572a;
  --c12-color: #2f8dfa;
}

@media (max-width: 768px) {
  :root {
    --navbar-height: 55px;
  }
}
