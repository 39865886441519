@mixin li-log-table-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);

  .law-enforcement-list {
    background-color: transparent;
    .col-label {
      color: $secondary;
    }
  }
}
