@mixin predicted-locations-form-theme($theme) {
  .left {
    border-right: 1px solid #d7dade;
  }

  .btn {
    background-color: #f6f6f6;
    border-color: transparent;
  }

  .selected-btn {
    background: #ebf0ff;
    color: #396aff;
  }

  .submit-btn {
    background: #008df6;
    color: white;
  }

  .days-select {
    ::ng-deep .mat-select-placeholder {
      color: #040930;
    }
  }

  app-spinner-loader {
    background: #f7f7f782;
  }

  .ng2-nouislider {
    .noUi-horizontal {
      .noUi-connect {
        background-color: #396aff !important;
      }

      .noUi-handle {
        background: #fff;
        box-shadow: 0 7px 10px 0 rgba(52, 58, 62, 0.18),
          0 2px 11px -8px rgba(0, 0, 0, 0.66);
        border: 1px solid rgba(52, 58, 62, 0.18);
      }
    }
  }
}
