@mixin purchase-info-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .purchase-info-popup {
    mat-label,
    .label {
      color: $secondary;
    }
  }

  .purchase-info-popup-actions {
    .reset {
      border: solid 1px #979797;
      box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);
      background-color: $primary;
    }

    .save {
      background-color: $accent;
      color: $primary;
    }
  }

  .purchase-info-popup-title {
    color: $secondary;
  }
}
