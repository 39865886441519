@mixin photo-carousel-modal-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);

  $transition_time: 0.2s;

  .transition {
    transition: all $transition_time ease-in-out;
  }

  .carousel {
    .photo-carousel-modal-content {
      .item {
        // using delay to prevent left change show error
        &.fade_animation {
          transition: opacity 0.295s linear $transition_time;
        }
      }
    }

    .ball {
      background: black;

      &.visible {
        opacity: 1;
      }
    }

    .progress {
      background: #ff5252;
    }

    .click-area {
      i {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
