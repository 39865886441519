@mixin quick-query-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $darker-grey: mat-color($custom-colors, darker-grey);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .quick-query-input-container {
    background-color: $darker-grey;
    .remove-btn {
      color: #b3b3b2;
    }
  }

  .quick-query-input-desktop {
    background-color: $darker-grey !important;
  }

  .quick-query-mobile {
    box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    background-color: $primary;

    .quick-query-input-mobile {
      background-color: transparent;
    }

    .submit-btn {
      background-color: #314a46;
      color: $primary;
    }
  }
}

@media screen and (max-width: 480px) {
  .iti__flag {
    background-color: #fff;
  }
}
