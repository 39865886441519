@mixin searchIntel-queue-theme($theme) {
  .img-block {
    .img-wrp {
      .round-checkbox {
        ::ng-deep {
          .mat-checkbox-label {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .queue-list {
    border-top: 1px solid #d7dade;
  }

  .queue-target {
    border: 1px solid #ccc;
    .target-photo {
      background-color: transparent;
    }
  }

  .queue-search-input {
    border: solid 1px #dddfe2;
    .remove-btn {
      color: #b3b3b2;
    }
  }
}
