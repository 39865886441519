@mixin location-query-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $grey: mat-color($custom-colors, grey);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .location-query-top {
    .imsis-input {
      background-color: $grey;
    }

    .hasError {
      background-color: rgba(253, 0, 0, 0.24);
    }
  }

  .location-query-bottom {
    .reset {
      color: $font1;
      border: 1px solid #dcdcdc;
    }

    .submit {
      background-color: $accent;
      color: $primary;
    }
  }
}
