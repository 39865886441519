@mixin case-common-workplaces-theme($theme) {
  .case-blocks {
    background-color: #fff;
    .block-header {
      background-color: #d8e1e4;
    }
  }

  .accordion-row {
    border-bottom: 1px solid #e3e0de;
  }
}
