// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'firefox.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@mixin application-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $green: mat-color($custom-colors, green);
  $font1: mat-color($custom-colors, font1);
  $font4: mat-color($custom-colors, font4);
  $font5: mat-color($custom-colors, font5);
  $border: mat-color($custom-colors, border);
  $background: mat-color($custom-colors, background-color);
  $font-accent: mat-color($custom-colors, font-accent);
  $dark-accent: mat-color($custom-colors, dark-accent);

  // DEBUG SCSS VARIABLES
  // @debug "green palette: #{$green}";

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);
  $profiler-second-font: mat-color($custom-colors, profiler-second-font);
  $profiler-third-font: mat-color($custom-colors, profiler-third-font);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);

  body {
    margin: 0px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: #f7f7f7;
    overscroll-behavior: contain;
  }

  .clickable {
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $primary;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    background-color: #d3d9df;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d3d9df;
  }

  .hidden {
    display: none !important;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .width-100 {
    width: 100%;
  }

  .height-100 {
    height: 100%;
  }

  .center-element {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .relative {
    position: relative;
  }

  .global-select-label {
    color: $font-accent;
    text-align: left;
    padding-bottom: 4px;
  }

  .global-select {
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    border: solid 1px $dark-accent;
    color: $secondary;
    font-size: 14px;
    text-align: left;
    display: inline-block;
    // height: 18px;
    width: 80%;
  }

  .global-input {
    padding: 5px 10px 5px 10px !important;
    border-radius: 2px;
    border: solid 1px $dark-accent !important;
    color: $secondary;
    font-size: 14px;
    text-align: left;
    background-color: transparent;
    // height: 18px;
    width: 100%;
    input {
      font-size: 12px;
    }
  }

  .global-textarea {
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    border: solid 1px $dark-accent;
    color: $secondary;
    font-size: 14px;
    text-align: left;
    background-color: transparent;
    height: 90px;
    width: 85%;
  }

  .global-disabled {
    cursor: not-allowed !important;
    color: #c0c0c0 !important;
    opacity: 0.7;
  }

  // global expanding search
  .expanding-search input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
  }

  .expanding-search input::-webkit-search-decoration,
  .expanding-search input::-webkit-search-cancel-button {
    display: none;
  }

  .expanding-search input[type='search'] {
    background: url(assets/static/images/search_dark.svg) no-repeat 9px center;
    background-size: 18px 18px;
    padding: 5px 5px 5px 30px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    margin: unset;
    width: 0;
    height: 15px;
    color: transparent;
    cursor: pointer;
    border: none;
  }

  .expanding-search input[type='search']:focus,
  .keep-open input[type='search'] {
    width: 100px;
    padding-left: 32px;
    background-color: $primary;
    cursor: auto;
    font-size: 14px;
    color: $font1;
  }

  .remove-padding-from-left-col {
    padding-left: 0 !important;
  }

  .remove-padding-from-right-col {
    padding-right: 0 !important;
  }

  .mat-mdc-paginator {
    background-color: transparent !important;
  }

  // .geo-paginator {
  //   position: fixed;
  //   bottom: 15px;
  //   left: 30vh;
  // }

  .create-target-dialog,
  .profiler-create-case-dialog,
  .delete-profiler-target-dialog {
    .mat-dialog-container {
      overflow-x: hidden;
      padding: 0px;
      border-radius: 8px;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .mat-form-field-appearance-outline {
    input {
      height: 2vh;
    }
  }

  .txt {
    .mat-form-field-label {
      height: 2vh !important;
    }
  }

  .target-actions-dialog,
  .mob-target-edit {
    .mat-dialog-container {
      padding: 24px 0px;
    }
  }

  .mat-ink-bar {
    color: $green !important;
    height: 4px !important;
    background-color: $accent !important;
  }

  .mat-checked {
    .mat-slide-toggle-bar,
    .mat-slide-toggle-bar-no-side-margin {
      background-color: #3f5c58 !important;
    }
  }

  .mat-slide-toggle-thumb {
    background-color: #97a3a8 !important;
  }

  .mat-slide-toggle-bar {
    background-color: #dddddd !important;
  }

  .mat-ripple-element {
    display: none;
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: transparent;

      .mat-checkbox-checkmark-path {
        stroke: #314a46 !important;
      }
    }
  }
  .swal2-styled {
    padding: 0.625rem 2rem !important;
  }
  .swal2-confirm {
    border-radius: 20px !important;
    background-color: #396aff !important;
    color: $primary !important;
    font-family: Assistant;
  }

  .swal2-confirm:focus {
    box-shadow: none !important;
  }

  .swal2-cancel {
    border-radius: 20px !important;
    color: #396aff !important;
    background-color: #f7f7f7 !important;
    border: 1px solid #396aff !important;
    font-family: Assistant;
  }

  .swal2-title {
    font-family: Ubuntu;
    font-size: 20px !important;
    color: #040930 !important;
    font-weight: normal !important;
  }

  .swal2-html-container {
    font-family: Assistant;
    color: #040930 !important;
  }

  .swal2-modal {
    background-color: $background !important;
    font-size: 15px !important;
    line-height: 1.23;
    text-align: center;
    color: $font1;
  }

  .dashboard-tabs,
  .query-tabs {
    .mat-tab-label {
      font-size: 23px;
      color: $secondary;
      // align-items: flex-start;
      // min-width: auto;
      // padding-right: 25px !important;
      // padding-left: 0px !important;
      min-width: auto;
      // margin: 0 10px;
      .mat-tab-label-content {
        font-size: 16px;
        color: #000;
      }
      &.mat-tab-label-active {
        .mat-tab-label-content {
          font-weight: bold;
        }
      }
    }

    .mat-tab-body-wrapper {
      height: 100%;
    }
  }

  .table-sub-tabs {
    .mat-tab-label {
      // height: 37px;
      // font-size: 16px;
      // color: #101011;
      // align-items: flex-start;
      // min-width: auto;
      // padding-right: 25px !important;
      // padding-left: 0px !important;
      // padding-top: 8px !important;
      .mat-tab-label-content {
        font-size: 14px;
        color: #000;
      }
      &.mat-tab-label-active {
        .mat-tab-label-content {
          font-weight: bold;
        }
      }
    }

    .mat-tab-body-content {
      overflow: hidden;
    }
  }

  .advanced-options-tabs {
    .mat-tab-label {
      font-size: 17px;
      color: $font1;
      padding-right: 25px !important;
      padding-left: 0px !important;
    }
  }

  .tasks-tabs {
    .mat-tab-label {
      color: #212121;
      font-size: 12px;
      min-width: auto;
    }

    .mat-tab-label-active {
      color: #e67c5a;
      text-decoration: underline;
      background-color: $primary;
    }

    .mat-ripple-element {
      display: none;
    }

    .mat-ink-bar {
      color: unset !important;
      background-color: unset !important;
      height: 0 !important;
    }
  }

  .mat-tab-label {
    text-align: left !important;
    justify-content: left !important;
    padding: 0 10px !important;
    font-weight: normal !important;
  }

  .query-check-box {
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }

  .quick-query {
    .mat-form-field-wrapper,
    .mat-form-field-appearance-legacy {
      padding-bottom: unset !important;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      position: unset;
      padding-top: 0px !important;
    }
  }

  .fab-btn .mat-button-wrapper {
    padding: 0 !important;
  }

  .mat-icon {
    overflow: visible !important;
  }

  .disabled .mat-icon {
    color: lightgray;
  }

  .exportType div.mat-select-arrow-wrapper {
    display: none;
  }

  .exportType.mat-select {
    display: inline;
  }

  .intel-options {
    .mat-mdc-menu-content {
      padding-bottom: 12px !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  // hide timeline and heatmap buttons
  .easy-button-button.disabled {
    display: none;
  }

  .expanding-search {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      width: auto;
    }
  }

  .filter {
    .mat-form-field-infix {
      border-top: unset !important;
    }
  }

  .log-margins .datetimepicker {
    .mat-form-field-infix {
      border-top: unset !important;
    }
  }

  // .zoom75 {
  //   zoom: 75%;
  // }

  .language-selector {
    .mat-select-value {
      overflow: unset;
    }

    .mat-select-arrow-wrapper {
      padding-left: 20px;
    }
  }

  .instagram-posts {
    .cdk-virtual-scroll-content-wrapper {
      position: unset !important;
    }
  }

  .quart {
    position: absolute;
    height: 50%;
    width: 50%;
    background: silver;
    transition: all 0.4s;
    cursor: pointer;
  }

  .quart:first-child {
    top: 0;
    left: 0;
    margin-bottom: 13px;
    margin-right: 13px;
  }

  .quart:nth-child(2) {
    top: 0;
    left: 50%;
    margin-bottom: 13px;
    margin-left: 13px;
  }

  .quart:nth-child(3) {
    top: 50%;
    left: 0;
    margin-top: 13px;
    margin-right: 13px;
  }

  .quart:nth-child(4) {
    top: 50%;
    left: 50%;
    margin-top: 13px;
    margin-left: 13px;
  }

  .loc-center {
    height: 87%;
    width: 87%;
    position: absolute;
    top: 6%;
    left: 6%;
    background: #ffb500;
    border-radius: 50%;
    text-align: center;
    line-height: 160px;
    border-color: $primary;
    border-width: 5px;
  }

  .loc-center-inner {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 26%;
    left: 26%;
    background: $primary;
    border-radius: 50%;
  }

  .quart:hover {
    background: dimgray;
  }

  .location-image {
    width: 120px;
    height: 120px;
    padding-top: 50px;
    pointer-events: none;
  }

  .phone-image {
    width: 80px;
    height: 80px;
    margin-left: 78px;
    margin-top: 78px;
    pointer-events: none;
  }

  .cell-image {
    width: 80px;
    height: 80px;
    margin-left: 78px;
    margin-top: 78px;
    pointer-events: none;
  }

  .pdf-image {
    width: 80px;
    height: 80px;
    margin-top: 78px;
    margin-left: 78px;
    z-index: 500;
    pointer-events: none;
  }

  .schedule-image {
    width: 80px;
    height: 80px;
    margin-top: 78px;
    margin-left: 78px;
    pointer-events: none;
  }

  .mat-mdc-menu-panel.notifications-menu {
    max-width: 600px !important;
    min-height: initial;

    .mat-mdc-menu-content {
      padding-top: 0px;
    }
  }

  // mobile bottom drawer height
  .mat-bottom-sheet-container {
    max-height: 88vh !important;
    min-height: 88vh;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 0px !important;
    // overflow-y: hidden !important;
  }

  .page {
    width: 500px;
    height: 500px;
    background-color: #f0c884;
  }

  .pin {
    border: 4px solid black;
    background-color: gray;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .pin-action-image {
    width: 25px;
    height: 25px;
    background-color: $secondary;
    border-radius: 50px;
    padding: 6px;
  }

  .pin-action-active {
    background: #e67c5a;
  }

  .circle-custom-marker {
    position: absolute;
    border: 2px solid #666666;
    border-radius: 50%;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
    filter: alpha(opacity=60);
    opacity: 0.6;
    margin-left: -45px; //FIXME: fix anchoring hack
    margin-top: -40px;
    -webkit-box-shadow: 0 0 1px 0px rgb(255, 255, 255);
    box-shadow: 0 0 1px 0px rgb(255, 255, 255);
    width: 105px;
    height: 105px;
    z-index: 86;

    -webkit-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    -ms-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .pin-marker-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
  }

  .row-pin {
    align-self: center;
    pointer-events: none;
  }

  .camera-preview-wrapper {
    display: flex;
  }

  .pin-camera-preview {
    width: 210px;
    height: 210px;
  }

  .camera-preview-stats {
    margin-left: 10px;
  }

  .camera-preview-stats-title {
    margin-bottom: 10px;
    font-size: 15px;
    color: cornflowerblue;
  }

  .query-checkbox,
  .query-check-box-geo4 {
    .mat-checkbox-frame {
      height: 15px;
      width: 15px;
      border-radius: 50%;
    }

    .mat-checkbox-inner-container {
      height: 15px;
      width: 15px;
      border-radius: 50%;
    }

    .mat-checkbox-background {
      border-radius: 50%;
    }
  }

  .query-check-box-geo4 {
    position: relative;
    // top: -7px;
  }

  // query checkbox to be white when checked
  .multipins {
    .query-checkbox,
    .query-check-box-geo4 {
      .mat-checkbox-frame {
        border-color: $primary;
      }

      .mat-checkbox-checkmark-path {
        stroke: $primary !important;
      }
    }
  }

  .phone-disabled {
    background-color: grey;
  }
  // force label color and position for focused fields
  .mat-focused:not(.mat-form-field-invalid) {
    mat-label {
      color: #999999;
    }
    .mat-form-field-label {
      position: absolute;
      padding-top: 0px;
    }
  }

  // changes the border around selected inputs for the whole project
  .mat-focused {
    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          border-top: 2px solid $green;
          border-left: 2px solid $green;
          border-bottom: 2px $green;
        }

        .mat-form-field-outline-gap {
          border-bottom: 2px solid $green;
        }

        .mat-form-field-outline-end {
          border-top: 2px solid $green;
          border-right: 2px solid $green;
          border-bottom: 2px solid $green;
        }
      }
    }
  }

  // changes the border around inputs with error for the whole project
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
    .mat-form-field-outline-thick {
    color: #d44b05;
  }

  // geo4 open search intelligence input
  .keywords {
    label {
      color: #999999;
      position: initial;
      padding-top: 5px;
    }

    .mat-form-field-infix {
      padding: 4px 0px !important;
    }
  }

  .time-slider {
    margin-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .noUi-connect {
      background: #314a46;
    }

    .noUi-tooltip {
      outline: none;
      border: none;
      background-color: transparent;
      color: #314a46;
    }
  }

  // navbar global search
  .nav-query {
    .query-select {
      .mat-select-trigger {
        padding-top: 16px;
        padding-left: 18px;
        padding-right: 13px;
      }
    }
  }

  // tactical checkbox
  .selected-tactical-item {
    .checkbox-tactical {
      .mat-checkbox-frame {
        border-color: $primary;
      }

      .mat-checkbox-checkmark-path {
        stroke: $primary !important;
      }
    }
  }

  .custom-marker-toolbox-popup {
    border-radius: 25px !important;
    padding: 6px 12px 6px 10px !important;
    background-color: $font-accent;
    color: $primary;
    font-weight: bold;
    font-size: 13px;
    position: absolute;
  }

  .custom-geo-marker-toolbox-popup {
    transform: translate(-50%, -50%);
    left: 50%;
    top: -20px;
    background: white;
    color: #2d2d2c;
    border-radius: 8px !important;
  }

  .custom-geo-marker-toolbox {
    height: 600px;
    width: 600px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    zoom: 22%;
    -moz-transform: scale(0.2, 0.2);
    border: 10px solid white;

    .third {
      position: absolute;
      overflow: hidden;
      height: 70%;
      width: 70%;
      background-color: #b3b3b2;
      transition: all 0.4s;
      opacity: 0.8;
      top: -20%;
      right: -20%;
      transform-origin: 0% 100%;
      border: 5px solid white;
    }

    .third:first-child {
      transform: rotate(0deg) skewY(30deg);
    }

    .third:nth-child(2) {
      transform: rotate(120deg) skewY(30deg);
    }

    .third:nth-child(3) {
      transform: rotate(240deg) skewY(30deg);
    }

    .pin-icon {
      height: 45%;
      width: 45%;
      position: absolute;
      top: 28%;
      left: 28%;
      border-radius: 50%;
      text-align: center;
    }

    .pin-icon:hover {
      transition: all 0.4s;
      background-color: $border;
    }

    .toolbox-item {
      display: none;
    }

    .third:hover {
      background: $border;
    }

    .location-image {
      height: 180px;
      top: 10px;
      position: relative;
      pointer-events: none;
    }

    .schedule-icon {
      transform: skewY(-30deg) rotate(120deg);
      position: relative;
      left: 120px;
      top: 155px;
      pointer-events: none;
    }

    .geofence-icon {
      left: 135px;
      top: 148px;
      position: relative;
      transform: skewY(-30deg);
      pointer-events: none;
    }

    .cells-icon {
      left: 120px;
      top: 140px;
      position: relative;
      transform: skewY(-30deg) rotate(240deg);
      pointer-events: none;
    }
  }

  // quick area of interest popup
  .quick-aoi-radio-buttons {
    font-size: 14px;
    color: $font1;

    input {
      position: relative;
      top: 2px;
    }

    input:nth-child(2) {
      margin-left: 25px;
    }
  }

  .call-log-custom-popup {
    width: 200px;
    border-radius: 4px;
    border: solid 1px $profiler-secondary;
    background-color: $primary;
    font-size: 11px;
    line-height: 1.18;
    text-align: center;
    color: #2a2a2a;
    padding: 5px 14px;
  }

  // google maps popup UI
  .gm-style {
    .agm-info-window-content {
      box-shadow: none !important;
      border-radius: 5px !important;
      height: auto;
      padding: 6px 20px 6px 10px !important;
      background-color: $font-accent;
      color: $primary;
      font-weight: bold;
      width: 120px;
      text-align: center;
    }

    .gm-style-iw-t::after {
      display: none;
    }

    .gm-style-iw-d {
      overflow: hidden !important;
    }

    .gm-style-iw-ch {
      padding: 0;
    }

    .gm-ui-hover-effect {
      margin-right: -13px !important;
      margin-bottom: -13px !important;
      width: 12px !important;
      height: 12px !important;

      span {
        width: 12px !important;
        height: 12px !important;
        margin: 0 !important;
      }
      img {
        content: url(assets/static/images/close_black.svg) !important;
        height: 8px !important;
        margin: 5px !important;
      }
    }
  }

  .case-overview-custom-popup {
    width: 200px;
    border-radius: 4px;
    background-color: #221f21;
    font-size: 11px;
    line-height: 1.18;
    text-align: center;
    padding: 5px 14px;
    font-weight: bold;
    color: $primary;
  }

  .mobile {
    .gm-style {
      margin-top: 15vh !important;
    }
  }

  .burger-menu {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 100;
    height: initial;
    width: 22px;
  }

  .selected-item {
    border-radius: 4px;
    background-color: $dark-accent;
    font-weight: bold;
    color: $primary;
  }

  .mat-input-element {
    caret-color: $secondary;
  }

  .custom-snackbar {
    color: $primary !important;

    .mat-mdc-snack-bar-action {
      color: $primary !important;
    }
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $accent !important;
  }

  .title-row {
    height: 4vh;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.09px;
    color: $profiler-third-font;
  }

  // HELPERS
  .margin-right10 {
    margin-right: 10px !important;
  }

  .padding-bottom-10 {
    padding-bottom: 10px !important;
  }

  .padding-top-10 {
    padding-top: 10px !important;
  }

  .media-tabs {
    margin-bottom: 10px;
  }

  .media-box {
    height: auto;
    border-radius: 5px;
    background-color: $primary;
    padding: 10px;
    margin: 5px 10px 5px 5px;
  }

  .no-result {
    margin-top: 10px;
  }

  .profiler-swal {
    .swal2-header {
      align-items: start !important;
    }

    .swal2-title {
      font-size: 32px !important;
      letter-spacing: -0.21px;
      color: rgba(51, 50, 56, 0.84) !important;
      text-align: left !important;
      margin-top: 3vh !important;
      margin-bottom: 2vh !important;
    }

    .delete-text {
      text-align: left;
      font-size: 17px;
      letter-spacing: -0.11px;
      color: rgba(35, 35, 39, 0.84);
      margin-bottom: 2vh;
    }

    .delete-sub-text {
      text-align: left;
      font-size: 14px;
      letter-spacing: -0.09px;
      color: #ff0000;
      margin-bottom: 2vh;
    }

    img {
      margin-left: unset !important;
    }

    .swal2-confirm {
      font-size: 17px !important;
      letter-spacing: -0.11px !important;
      color: $primary !important;
      border-radius: 3px !important;
      background-color: #ff0000 !important;
    }

    .swal2-cancel {
      border-radius: 3px !important;
      border: solid 2px $profiler-secondary !important;
      font-size: 17px !important;
      letter-spacing: -0.11px !important;
      color: $profiler-second-font !important;
    }
  }

  .map-overview-slider {
    margin-top: 0;
    margin-bottom: 0;

    nouislider {
      margin-top: 0;
      margin-bottom: 0;
    }

    .noUi-target {
      cursor: pointer;
      height: 12px;

      .noUi-base {
        background-color: $primary;
        border: solid 1px rgba(122, 125, 128, 0.3);
        border-radius: 3px;
        height: 12px;

        .noUi-connects {
          height: 4px;
          top: 3px;
          bottom: 3px;

          .noUi-connect {
            background-color: #008df6;
          }
        }
      }
    }

    .noUi-handle {
      width: 10px;
      height: 25px;
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(171, 175, 179, 0.6),
        inset 0 -3px 6px 0 rgba(122, 125, 128, 0.2);
      border: solid 1px rgba(122, 125, 128, 0.3);
      background-color: $primary;
    }

    .noUi-handle-upper,
    .noUi-handle-lower {
      right: -5px !important;
      top: -8px !important;

      .noUi-tooltip {
        color: #2a2a2a;
        font-size: 12px;
        border: 1px solid $profiler-secondary;
        border-radius: 4px;
      }
    }

    .noUi-handle:before,
    .noUi-handle:after {
      display: none;
    }
  }

  .avatar-age-slider {
    margin-top: 0;
    margin-bottom: 0;

    nouislider {
      margin-top: 0;
      margin-bottom: 0;
    }

    .noUi-target {
      cursor: pointer;
      height: 4px;
      border: 0;
      background: #d7dade;

      .noUi-base {
        height: 4px;

        .noUi-connects {
          height: 4px;
          top: 0px;
          bottom: 0px;

          .noUi-connect {
            background-color: #008df6;
          }
        }
      }
    }

    .noUi-handle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 0px 0px 8px rgba(57, 106, 255, 0.5);
      background: #008df6;
      cursor: pointer;
    }

    .noUi-handle-upper,
    .noUi-handle-lower {
      right: -5px !important;
      top: -3px !important;

      .noUi-tooltip {
        color: #fff;
        background: #008df6;
        font-size: 14px;
        border-radius: 16px;
        width: 36px;
        height: 22px;
        top: 15px;
        bottom: unset;
        padding: 2px;
        border: 0;
      }
    }

    .noUi-handle:before,
    .noUi-handle:after {
      display: none;
    }
  }

  .profiler-target-form,
  .profiler-create-target-dialog {
    .mat-dialog-container {
      height: 90vh;
      overflow: auto;
    }
  }

  .profiler-no-data {
    padding: 7px 0px 7px 10px;
    width: 100%;
    border: unset;
    text-align: start;
    font-size: 14px;
    color: $profiler-second-font;
    margin-top: 2px;
  }

  .global-text-overflow-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .profiler-custom-map-dropdown {
    border-radius: 5px !important;
    box-shadow: 0 0 0 1px #e0e7ee;

    .mat-mdc-menu-content {
      padding: 0px !important;
    }

    .layer-section {
      color: #445870;

      .title {
        background-color: #bec5c8;
        font-size: 10px;
        padding: 10px 0;
        font-weight: bold;
        text-align: center;
      }

      .layer-option {
        font-size: 14px;
        line-height: 1.29;
        padding: 0px 26px 21px 26px;
        cursor: pointer;
        position: relative;

        .eye {
          position: absolute;
          right: 9px;
          bottom: 23px;
          height: 15px;
        }
      }
    }

    div.layer-section div:nth-child(2) {
      padding-top: 8px;
    }

    div.layer-section div:last-child {
      padding-bottom: 8px;

      .eye {
        bottom: 10px;
      }
    }
  }

  .inline-flex {
    display: inline-flex;
  }

  .inline-block {
    display: inline-block;
  }

  .inline {
    display: inline;
  }

  .block {
    display: block;
  }

  .profiler-call-log-custom-dropdown {
    border-radius: 5px;
    box-shadow: 0 0 0 0 #e0e7ee;
    background-color: $primary;

    .mat-mdc-menu-content {
      padding: 14px 0px 0px 0px !important;
    }

    .profiler-call-log-custom-dropdown-option {
      font-size: 14px;
      line-height: 1.29;
      text-align: left;
      color: #445870;
      padding: 0px 70px 14px 13px;
      position: relative;
      cursor: pointer;

      .eye {
        position: absolute;
        right: 11px;
        top: 0px;
        font-size: 16px;
        height: 16px;
        width: 16px;
      }
    }

    .profiler-call-log-custom-dropdown-inactive-option {
      opacity: 0.6;
    }
  }

  .profiler-call-log-header-filter-option {
    font-size: 12px !important;
    line-height: 1.5 !important;
    color: #445870 !important;
    position: relative !important;
    padding: 0px 10px !important;

    .eye {
      height: 16px;
      position: absolute;
      right: 4px;
    }
  }

  .select-location-dialog,
  .profiler-geolocation-details-dialog,
  .no-padding-dialog {
    .mat-dialog-container {
      padding: 0px;
    }
  }

  .photo-dialog {
    .mat-dialog-container {
      padding: 0px;
    }
  }

  .profiler-common-people-dialog {
    .mat-dialog-container {
      padding: 0px;
    }
  }

  .advanced-query-geolocation-dialog {
    .mat-dialog-container {
      padding: 20px 10px;
    }
  }

  .sync-with-table-toggle {
    mat-slide-toggle {
      .mat-slide-toggle-bar {
        height: 22px;
        border-radius: 16px;
        background-color: $profiler-secondary;
        width: 39px;

        .mat-slide-toggle-thumb-container {
          top: 1px;
          left: 1px;

          .mat-slide-toggle-thumb {
            background-color: $primary !important;
            box-shadow: none;
          }
        }
      }
    }
  }

  .eye-enabled {
    color: #3e4246;
  }

  .eye-disabled {
    color: #9a9d9f;
  }

  .profiler.body-background-color {
    top: 50px;
    position: relative;
  }

  .body-background-color {
    background-color: $background !important;
  }

  .timebar-kl {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }

  .search-results-chart {
    width: 100%;
    height: 50%;
    display: inline-flex;
  }

  .search-results-map {
    width: 100%;
    height: 50%;
    display: inline-flex;
  }

  .call-log-la-section {
    .call-log-kl-chart {
      height: 44vh !important;
    }
  }

  .call-log-la-maximized-section {
    .call-log-kl-chart {
      height: 72vh;
    }
  }

  .discover-map-dialog {
    .mat-dialog-container {
      padding-top: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }

  .target-wrp {
    margin: 10px 0;

    .target-search-input {
      border: 1px solid #ccc;
      padding: 0 5px;
      width: 150px;
      margin: 10px 0;

      input {
        width: 100% !important;
        padding: 5px;
        appearance: none;
        outline: none;
        margin-top: 0;
      }
    }
  }

  .spinner-loader {
    display: block;
    margin: auto auto;
    margin-top: 45vh;
  }

  .loader-position {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .kl-chart-tooltip {
    position: absolute;
    z-index: 10;
    padding: 10px 18px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 14px;
    background-color: rgba(59, 72, 80, 0.9);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    color: $primary;
    text-align: center;
    white-space: pre-wrap;
  }

  .kl-timebar-tooltip {
    background-color: rgb(105, 105, 105);
    color: $primary;
    height: auto;
    width: auto;
    padding: 15px;
    font-size: 13px;
    position: absolute;
    pointer-events: none;
    display: block;
    float: left;
    user-select: none;
  }

  // link analysis
  .degrees-slider {
    .mat-slider-thumb-label {
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
      width: 20px !important;
      height: 20px !important;
      top: -25px !important;
      right: -10px !important;
      .mat-slider-thumb-label-text {
        opacity: 1 !important;
        color: $primary;
      }
    }

    .mat-slider-thumb {
      transform: scale(0) !important;
    }
  }
}
.empty-card-state {
  width: 88px;
  height: 88px;
  background-color: #ebebeb;
  border-radius: 50%;
  margin: auto;
  img {
    padding: 20%;
  }
}

.text-center {
  text-align: center !important;
}

.overview-sections-spinner {
  position: absolute !important;
  background-color: transparent;
  top: 4%;
  left: 43%;
  transform: translate(-50%, -50%);
}

// query status color
.red-font {
  color: red;
}

.green-font {
  color: green;
}

// Hide the reCAPTCHA badge on the bottom right.
// Don't use display: none, it will disable
// spam checking.
.grecaptcha-badge {
  visibility: hidden;
}

.cdk-overlay-container {
  z-index: 1000000 !important;
}

.toast-container-bottom-pushed {
  bottom: 0 !important;
}

.toast-bottom-right {
  right: 2vw !important;
}

.mobile-drawer-position {
  top: 0 !important;
  right: 0 !important;
  height: 100vh;
}

.social-activity-modal {
  .header,
  .body,
  .footer {
    background: white !important;
  }
}
.social-activity-options {
  padding: 18px;
}
.social-activity-option {
  margin: 7px 0;

  .mat-option-text {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background: #396aff !important;
  }
}

.mobile-filters-container {
  .mat-checkbox {
    width: 100%;

    .mat-checkbox-layout {
      width: 100%;
      justify-content: space-between;

      .mat-checkbox-label-before .mat-checkbox-inner-container {
        margin-right: 0;
      }

      .mat-checkbox-label {
        width: 100%;
      }
    }
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #396aff !important;
  }

  .mat-checkbox-checked .mat-checkbox-background .mat-checkbox-checkmark-path {
    stroke: #fafafa !important;
  }

  mat-checkbox.mat-checkbox-disabled.mat-checkbox-checked
    .mat-checkbox-background {
    background-color: gray !important;
  }

  mat-checkbox .mat-checkbox-inner-container {
    background: #ffffff;
  }
}

.modal-footer-container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px #ededed solid;

  button {
    padding: 0 41px !important;

    .mat-button-wrapper {
      font-size: 16px;
      line-height: 18px;
    }

    &.disabled {
      pointer-events: none;

      &.cp-btn-primary {
        background-color: #ccd9ff;
      }

      &.cp-btn-ghost {
        border-color: #ccd9ff;
        color: #ccd9ff;
      }
    }
  }
}

.result-head,
.search-history-wrapper {
  .filter-button {
    &.active {
      .icon-text-button {
        color: #3365ff;
      }
    }
  }
}

app-social-activity-alerts-table {
  app-feature-table {
    display: block;
    width: 100%;
    overflow: scroll;
  }
}

.group-analyser,
.im-group-list,
.members-list {
  .w-100 {
    width: 100%;
  }

  .t-body-container {
    margin: 0;
  }

  thead {
    tr {
      th:last-of-type {
        .filter-header {
          justify-content: flex-end;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}

.intel-snackbar-flex-wrapper {
  intellectus-icon-text-button {
    .icon-text-button {
      color: var(--i9s-btn-secondary-alt-color) !important;

      &:hover {
        color: var(--i9s-btn-secondary-alt-hover-bg) !important;
      }

      .content {
        padding: 0 !important;
      }
    }
  }
}

.group-analyser-container {
  .mat-tab-label {
    display: flex;
    text-align: center !important;
    justify-content: center !important;
    opacity: 1;
    min-width: auto;

    .mat-tab-label-content {
      margin-bottom: 22px;
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }

    &.mat-tab-label-active {
      color: #3365ff;
    }

    &.title-tab {
      margin-right: 50px;
      padding-left: 40px !important;

      .mat-tab-label-content {
        font-size: 22px !important;
        font-weight: 400 !important;
        line-height: 25px !important;
        color: #040930 !important;
      }
    }
  }

  .mat-ink-bar {
    height: 2px !important;
    background-color: #3365ff !important;
  }

  .group-selector {
    .mat-chip {
      min-height: 25px !important;
      margin: 0 !important;
      padding: 2px 15px;
      font-family: Assistant !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: #040930 !important;
    }

    .mat-select-min-line:not(.mat-select-placeholder) {
      display: none !important;
    }
  }
}

.group-analyser-media-dialog {
  .mat-dialog-container {
    padding: 0;
    background: transparent;
  }

  .copy-button {
    fe-platform-fa-pro-icon {
      color: #4c4f67;
    }
  }
}

.group-analyser-graph-popup {
  display: flex;
  flex-direction: column;
  width: 282px;
  padding: 16px 22px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  user-select: none;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text {
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    app-target-img {
      img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-start;
  }
}
