@mixin blacklist-number-dialog-theme($theme) {
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .blacklist-title {
    color: $font1;
  }

  .add-blaclist-actions {
    .save {
      background-color: $accent;
      color: $primary;
    }
  }
}
