@mixin profiler-related-people-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .profiler-related-people {
    background-color: $primary;

    .view-related-people {
      color: #008df6;
    }

    .view-text {
      color: #005cff;
    }

    .relation-titles {
      color: #84878c;
    }

    .image-name {
      color: #3c4144;
    }

    .platform-image-xl {
      background-color: $primary;
    }

    .platform-image-xs {
      background-color: $primary;
    }
  }
}
