@mixin request-location-history-imsi-dialog-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font4);
  $background: mat-color($custom-colors, background-color);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .request-location-history-imsi-dialog {
    .location-imsi-title {
      color: $font1;
    }

    .label {
      color: $font1;
    }

    .value {
      color: $font1;
    }
  }

  .request-location-history-imsi-dialog-actions {
    .ok {
      background-color: #314a46;
      color: $primary;
    }

    .cancel {
      background-color: $background;
      color: #878787;
      border: solid 1px #eae6e6;
    }
  }
}
