@mixin profiler-sentiment-analysis-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .profiler-sentiment-analysis {
    background-color: $primary;
  }

  .sentiment-analysis-table {
    background: transparent !important;

    .table-column {
      color: black;
    }
  }

  .cell-text td:nth-child(odd) {
    background-color: $primary;
  }

  .cell-text td:nth-child(even) {
    background-color: #f6f7f9;
  }
}
