@mixin reset-password-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .reset-password-content {
    .reset-password-title {
      color: $font1;
    }

    .reset-password-btn {
      background-color: $accent;
      color: $primary;
    }
  }
}
