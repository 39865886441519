@mixin profiler-feed-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);

  .feed-header-container {
    background-color: #fff;
  }

  .select-day-option {
    background-color: $primary;
    border: 1px solid $profiler-secondary;
    color: #2a2a2a;
  }

  .select-platform-option {
    background-color: $primary;
    border: 1px solid $profiler-secondary;
    color: #2a2a2a;
  }

  .feed-selected-map-option {
    background-color: #fff;
  }

  .profiler-action-bar-type-filter {
    border-left: solid 1px #dddfe2;

    mat-button,
    .mat-button {
      color: #161616;
    }
  }

  .profiler-action-bar-clear-filter {
    color: #161616;
  }

  .profiler-action-bar-content-filters {
    .profiler-action-bar-location-filter {
      .filter-by-location-place {
        color: #161616;
      }
    }
  }

  .feed-container {
    .mat-ink-bar {
      color: mat-color($custom-colors, profiler-accent) !important;
      background-color: mat-color($custom-colors, profiler-accent) !important;
    }
  }
}
