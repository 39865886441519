@mixin advanced-queries-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $green: mat-color($custom-colors, green);
  $font1: mat-color($custom-colors, font1);

  .advanced-queries-title {
    color: $font1;
  }

  .advanced-options-tabs {
    ::ng-deep {
      mat-tab {
        color: $green;
      }
    }
  }
}
