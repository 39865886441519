@mixin navbar-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background: mat-color($custom-colors, background-color);
  $background-color3: mat-color($custom-colors, background-color3);

  .navbar {
    background-color: $background;
    border-bottom: 1px solid #e3e0de;

    .user-icon {
      color: #434343;
    }

    .logo-text {
      color: #434444;
    }

    .image-analysis {
      color: $background;
    }
  }

  .loader-show {
    background-color: #faf6f3;
  }
}
