@mixin case-breadcrumbs-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background-color: mat-color($custom-colors, background-color);

  .breadcrumbs-wrapper {
    background-color: $background-color;
  }

  .breadcrumbs {
    color: #c1c1c1;
  }

  .breadcrumbs-target {
    color: #828282;
  }
}
