@mixin map-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font4: mat-color($custom-colors, font4);
  $background: mat-color($custom-colors, background-color);

  .enabled-toolbox-pin {
    background-color: $background;
  }

  .follow-enabled {
    background-color: $font4 !important;
  }

  .cached-antennas {
    background-color: $font4 !important;
  }
}
