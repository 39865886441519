@mixin searchIntel-filters-theme($theme) {
  .filter-block {
    background-color: #fff;
    li {
      .mat-checkbox-label {
        color: #000;
      }

      span {
        color: #ccc;
      }
    }
  }
}
