@mixin case-relations-theme($theme) {
  .case-blocks {
    background-color: #fff;
    .block-header {
      background-color: #d8e1e4;
    }
  }

  .accordion-row {
    border-bottom: 1px solid #e3e0de;
    .accordion-relations {
      width: 40%;
    }
  }

  .view-wrp {
    color: #008df6;
  }

  .pop-list {
    li {
      border-bottom: 1px solid #ccc;
      .pop-list-key {
        color: #292929;
      }
      .pop-list-val {
        color: #323232;
        a {
          color: #005cff;
        }
      }
    }
  }
}
