@mixin searchIntel-results-theme($theme) {
  .result-data {
    border: 1px solid #d8e1e4;
    background-color: #fff;
  }

  .data-block {
    ul.data-list {
      li {
        .personal-details {
          > span {
            ::ng-deep {
              .mat-checkbox {
                &:hover {
                  color: #38cb87;

                  .mat-checkbox-background {
                    border-color: #38cb87;
                  }

                  mat-icon {
                    color: #38cb87;
                  }
                }

                .mat-checkbox-frame {
                  background-color: transparent;
                  border-color: #38cb87;
                }

                .mat-checkbox-background {
                  border-color: #000;
                }

                &.mat-checkbox-checked {
                  mat-icon {
                    color: #38cb87;
                  }

                  span {
                    color: #38cb87;
                  }

                  .mat-checkbox-label {
                    color: #38cb87;
                  }

                  .mat-checkbox-background {
                    border-color: #38cb87;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .img-block {
    .img-wrp {
      ::ng-deep {
        .mat-checkbox {
          .mat-checkbox-label {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .select-all {
    span {
      color: #aeaaaa;

      ::ng-deep {
        mat-checkbox .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            border-color: #aeaaaa;
          }
        }
      }
    }
  }

  .open-web {
    color: #3c4144;
  }

  .article-box {
    background-color: #f3f3f3;
  }

  .article-title {
    color: #3c4144;
  }

  .article-title-in-queue {
    color: #38cb87;
  }

  .article-url {
    opacity: 0.5;
    font-family: Assistant;
    font-size: 14px;
    color: #040930;
    word-break: break-all;
  }

  .article-content {
    color: #3c4144;
  }

  .see-more-button {
    color: #3c4144;
  }
}
