@mixin import-data-request-dialog-theme($theme) {
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .import-data-request-dialog {
    color: #333;

    .cancel {
      border: solid 1px #979797;
      background-color: $primary;
    }

    .ok {
      background-color: $accent;
      color: $primary;
    }
  }
}
