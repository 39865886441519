@mixin query-list-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $font1: mat-color($custom-colors, font1);
  $font2: mat-color($custom-colors, font2);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .query-list-labels-row {
    .col-label {
      color: $secondary;
    }
  }

  .no-queries-msg {
    color: $font2;
  }

  .querylist-filter-titles {
    .group-id,
    .history-id,
    .query-user {
      color: $secondary;
    }
  }

  // mobile
  .querylist-mobile {
    background-color: $primary;
  }

  .query-selected-icon {
    color: #333333;
  }
}
