@mixin case-members-theme($theme) {
  .case-blocks {
    background-color: #fff;

    .block-header {
      background-color: #d8e1e4;
    }

    .expand-card-view {
      color: #008df6;
    }
  }

  .accordion-row {
    border-bottom: 1px solid #e3e0de;
  }

  .targets-table {
    tr {
      &:nth-child(odd) {
        background-color: rgba(216, 225, 228, 0.36);
      }
    }
  }
}
