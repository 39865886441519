@mixin dashboard-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .map {
    background-color: transparent;
  }

  .geo4-mobile-sidenav-container {
    mat-sidenav {
      background-color: $primary;
    }
    .bottom-container {
      background-color: $primary;
    }

    .query-detail {
      .telno {
        background-color: $primary;
        box-shadow: 0 2px 8px 0 #314a46;
        color: $font1;
      }

      .actions {
        button {
          background-color: $primary;
          box-shadow: 0 2px 8px 0 #314a46;
        }
      }
    }
  }
}

.target-popup {
  background-color: #1880ff;
  color: #fff;
}
