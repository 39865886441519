@mixin notifications-list-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background: mat-color($custom-colors, background-color);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .notification-icon {
    color: $background;
  }
}
