@mixin login-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .login-page-wrp {
    .logo-wrp {
      p {
        color: #5b5a5a;
      }
    }
    .login-form-wrp {
      border: solid 2px #3e6bb1;
    }

    .login-card {
      ::ng-deep {
        mat-form-field {
          color: #777777;

          .label {
            color: #777777;
          }
        }
      }

      .login-btn {
        color: $primary;
      }
    }

    .two-step-auth {
      color: #414242;
    }

    .cvtp-login-card {
      background-color: #faf6f3;
      border: 1px solid #cfd0d1;

      ::ng-deep {
        mat-form-field {
          color: #777777;

          .mat-form-field-underline {
            background-color: #cfd0d1;
          }

          .label {
            color: #777777;
          }
        }
      }

      .login-btn button {
        color: white;
        background-color: #129793;

        &:hover {
          background-color: #6ebfba;
        }
      }
    }

    .error-helper {
      color: #414242;
    }
  }

  .mxnl {
    .footer {
      border: 1px solid $primary;
      background-color: $primary;
    }
  }

  .geoloc-login {
    .login-btn {
      .login-action {
        background-color: #0072c0 !important;
      }
    }
  }

  .reset-2fa {
    .email-content {
      color: #414242;
    }
  }
}
