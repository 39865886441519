@mixin profiler-overview-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $background-color: mat-color($custom-colors, background-color);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);

  .more-options-btn {
    color: $profiler-font;
  }

  .btn-locate {
    border: solid 1px $profiler-secondary;
    background-color: $primary;
  }

  .top-bar-options {
    color: mat-color($custom-colors, profiler-accent);

    button {
      &.active {
        background-color: mat-color($custom-colors, profiler-accent) !important;
        color: mat-color($custom-colors, background-color4);
      }
    }
  }

  .profiler-dashboard-top-content {
    .mat-ink-bar {
      color: mat-color($custom-colors, profiler-accent) !important;
      background-color: mat-color($custom-colors, profiler-accent) !important;
    }
  }

  .top-options-menu {
    .top-menu-title {
      border-color: mat-color($custom-colors, profiler-secondary);
    }

    .top-menu-options {
      border-color: mat-color($custom-colors, profiler-secondary);
    }

    .top-menu-footer {
      button {
        background-color: mat-color($custom-colors, profiler-accent);
        color: mat-color($custom-colors, background-color4);
      }
    }
  }

  .poe-toggle-button {
    padding: 0 20px;
  }

  .mat-checked {
    .mat-slide-toggle-bar,
    .mat-slide-toggle-bar-no-side-margin {
      background-color: mat-color($custom-colors, profiler-accent) !important;
    }
  }
  .mat-slide-toggle-thumb-container {
    top: 0 !important;
    height: 18px;
  }

  .mat-slide-toggle-content {
    color: $profiler-font;
  }

  .mat-slide-toggle-thumb {
    background-color: transparent !important;
    box-shadow: none !important;
    position: relative;
    height: 18px !important;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: mat-color($custom-colors, background-color4) !important;
    }
  }

  .mat-slide-toggle-bar {
    height: 18px !important;
    border-radius: 18px !important;
    background-color: mat-color($custom-colors, background-color1) !important;
  }

  .mat-ripple-element {
    display: none;
  }
}
