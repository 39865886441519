@mixin user-options-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font3: mat-color($custom-colors, font3);
  $font-accent: mat-color($custom-colors, font-accent);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .user-options-top-row {
    button.mat-button {
      span {
        text-transform: none;
      }
    }

    .user-options-actions {
      .change-password {
        background-color: $accent;
        color: $primary;
        span {
          color: $primary;
        }
      }

      .user-options-credits {
        color: $font3;
      }
    }
  }

  .geo4-mobile-user-options {
    mat-sidenav {
      background-color: $primary;
    }

    mat-sidenav-content {
      .user-options-credits {
        color: $font-accent;
      }

      .change-password {
        background-color: $accent;
        color: $primary;
        span {
          color: $primary;
        }
      }

      .global-select-label {
        color: $font-accent;
      }
    }
  }
}
