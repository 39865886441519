.hide-flag ::ng-deep .selected-flag {
  visibility: hidden;
}

.mat-chip-list-wrapper input.mat-input-element {
  padding: 10px;
  border: none;
}

@mixin simple-query-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $grey: mat-color($custom-colors, grey);
  $font1: mat-color($custom-colors, font1);

  .queries-input-top {
    .phones-input {
      background-color: #e0e0e0;
    }

    .hasError {
      background-color: rgba(253, 0, 0, 0.24);
    }

    .imsi-input {
      background-color: #dfdfdf;
    }

    .invalidValues {
      color: red;
    }
  }

  .error {
    color: red;
  }
}
