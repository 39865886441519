// Colors palette
$bright-blue-30: #132f86;
$bright-blue-40: #1a3fb2;
$bright-blue-50: #204fdf;
$bright-blue: #3365ff;
$bright-blue-70: #668cff;
$bright-blue-80: #99b2ff;
$bright-blue-90: #ccd9ff;
$bright-blue-95: #e5ecff;
$bright-blue-98: #f5f7ff;

$dark-blue: #040930;
$dark-blue-25: #303350;
$dark-blue-35: #4c4f67;
$dark-blue-45: #676a7e;
$dark-blue-55: #818497;
$dark-blue-65: #9d9faf;
$dark-blue-75: #b9bac6;
$dark-blue-85: #d5d6dd;
$dark-blue-90: #e3e3e8;

$white-10: #ffffff1a;
$white-20: #ffffff33;
$white-30: #ffffff4d;
$white-40: #ffffff66;
$white-50: #ffffff80;
$white-60: #ffffff99;
$white-70: #ffffffb2;
$white-80: #ffffffcc;
$white-90: #ffffffe6;
$white: #fff;
$white-smoke: #efefef;

$gray-97: #f7f7f7;
$gray-93: #ededed;
$gray-87: #dedede;
$black: #000000;

// ---------- variables for CP ----------
// Blue Shades
$darkBlue: #204cd3;
$brightBlue: #396aff;
$mediumBlue: #a4baff;

// Gray Shades
$lightBlue: #040407;
$darkGray: #adafb2;
$mediumGray: #d7dade;
$lightGray: #ededed;
$gray: #d8e1e4;
$gray-50: #f9f9f9;
$gray-100: #424852;
$gray-200: #040930;
$gray-settings-base: #404040;
$gray-borders-primary: #e6e6e6;
$color-muted: #484848;

// Green
$green-10: #5cbe0e1a;
$green-20: #5cbe0e33;
$green-30: #5cbe0e4d;
$green-40: #5cbe0e66;
$green-50: #5cbe0e80;
$green-60: #5cbe0e99;
$green-70: #5cbe0eb3;
$green-80: #5cbe0ecc;
$green-90: #5cbe0ee6;
$green-100: #5cbe0e;

// Orange
$orange-10: #f561111a;
$orange-20: #f5611133;
$orange-30: #f561114d;
$orange-40: #f5611166;
$orange-50: #f5611180;
$orange-60: #f5611199;
$orange-70: #f56111b3;
$orange-80: #f56111cc;
$orange-90: #f56111e6;
$orange-100: #f56111;

// Red
$red-10: #d805051a;
$red-20: #d8050533;
$red-30: #d805054d;
$red-40: #d8050566;
$red-50: #d8050580;
$red-60: #d8050599;
$red-70: #d80505b3;
$red-80: #d80505cc;
$red-90: #d80505e6;
$red-100: #d80505;

// Blue
$blue-10: #516fd91a;
$blue-20: #516fd933;
$blue-30: #516fd94d;
$blue-40: #516fd966;
$blue-50: #516fd980;
$blue-60: #516fd999;
$blue-70: #516fd9b3;
$blue-80: #516fd9cc;
$blue-90: #516fd9e6;
$blue-100: #516fd9;

// Gray
$dark-gray-10: #4c4f671a;
$dark-gray-20: #4c4f6733;
$dark-gray-30: #4c4f674d;
$dark-gray-40: #4c4f6766;
$dark-gray-50: #4c4f6780;
$dark-gray-60: #4c4f6799;
$dark-gray-70: #4c4f67b3;
$dark-gray-80: #4c4f67cc;
$dark-gray-90: #4c4f67e6;
$dark-gray-100: #4c4f67;

// Purple
$purple-10: #a55eea1a;
$purple-20: #a55eea33;
$purple-30: #a55eea4d;
$purple-40: #a55eea66;
$purple-50: #a55eea80;
$purple-60: #a55eea99;
$purple-70: #a55eeab3;
$purple-80: #a55eeacc;
$purple-90: #a55eeae6;
$purple-100: #a55eea;

// Light
$light-10: #f7f7f71a;
$light-20: #f7f7f733;
$light-30: #f7f7f74d;
$light-40: #f7f7f766;
$light-50: #f7f7f780;
$light-60: #f7f7f799;
$light-70: #f7f7f7b3;
$light-80: #f7f7f7cc;
$light-90: #f7f7f7e6;
$light-100: #f7f7f7;

:root {
  --black: #{$black};
  // Green
  --green-10: #{$green-10};
  --green-20: #{$green-20};
  --green-30: #{$green-30};
  --green-40: #{$green-40};
  --green-50: #{$green-50};
  --green-60: #{$green-60};
  --green-70: #{$green-70};
  --green-80: #{$green-80};
  --green-90: #{$green-90};
  --green-100: #{$green-100};

  // Orange
  --orange-10: #{$orange-10};
  --orange-20: #{$orange-20};
  --orange-30: #{$orange-30};
  --orange-40: #{$orange-40};
  --orange-50: #{$orange-50};
  --orange-60: #{$orange-60};
  --orange-70: #{$orange-70};
  --orange-80: #{$orange-80};
  --orange-90: #{$orange-90};
  --orange-100: #{$orange-100};

  // Red
  --red-10: #{$red-10};
  --red-20: #{$red-20};
  --red-30: #{$red-30};
  --red-40: #{$red-40};
  --red-50: #{$red-50};
  --red-60: #{$red-60};
  --red-70: #{$red-70};
  --red-80: #{$red-80};
  --red-90: #{$red-90};
  --red-100: #{$red-100};

  // Blue
  --blue-10: #{$blue-10};
  --blue-20: #{$blue-20};
  --blue-30: #{$blue-30};
  --blue-40: #{$blue-40};
  --blue-50: #{$blue-50};
  --blue-60: #{$blue-60};
  --blue-70: #{$blue-70};
  --blue-80: #{$blue-80};
  --blue-90: #{$blue-90};
  --blue-100: #{$blue-100};
  --bright-blue-95: #{$bright-blue-95};
  --bright-blue-98: #{$bright-blue-98};
  --bright-blue-90: #{$bright-blue-90};
  --bright-blue: #{$bright-blue};

  --dark-blue: #{$dark-blue};
  --dark-blue-25: #{$dark-blue-25};
  --dark-blue-35: #{$dark-blue-35};
  --dark-blue-45: #{$dark-blue-45};
  --dark-blue-55: #{$dark-blue-55};
  --dark-blue-65: #{$dark-blue-65};
  --dark-blue-75: #{$dark-blue-75};
  --dark-blue-85: #{$dark-blue-85};
  --dark-blue-90: #{$dark-blue-90};

  // Gray
  --dark-gray-10: #{$dark-gray-10};
  --dark-gray-20: #{$dark-gray-20};
  --dark-gray-30: #{$dark-gray-30};
  --dark-gray-40: #{$dark-gray-40};
  --dark-gray-50: #{$dark-gray-50};
  --dark-gray-60: #{$dark-gray-60};
  --dark-gray-70: #{$dark-gray-70};
  --dark-gray-80: #{$dark-gray-80};
  --dark-gray-90: #{$dark-gray-90};
  --dark-gray-100: #{$dark-gray-100};
  --gray-87: #{$gray-87};
  --gray-93: #{$gray-93};
  --gray-200: #{$gray-200};
  --gray-97: #{$gray-97};
  --light-gray: #{$lightGray};
  --medium-gray: #{$mediumGray};
  --borders-primary: #{$gray-borders-primary};
  --color-muted: #{$color-muted};

  // Purple
  --purple-10: #{$purple-10};
  --purple-20: #{$purple-20};
  --purple-30: #{$purple-30};
  --purple-40: #{$purple-40};
  --purple-50: #{$purple-50};
  --purple-60: #{$purple-60};
  --purple-70: #{$purple-70};
  --purple-80: #{$purple-80};
  --purple-90: #{$purple-90};
  --purple-100: #{$purple-100};

  // Light
  --light-10: #{$light-10};
  --light-20: #{$light-20};
  --light-30: #{$light-30};
  --light-40: #{$light-40};
  --light-50: #{$light-50};
  --light-60: #{$light-60};
  --light-70: #{$light-70};
  --light-80: #{$light-80};
  --light-90: #{$light-90};
  --light-100: #{$light-100};

  // White
  --white-10: #{$white-10};
  --white-20: #{$white-20};
  --white-30: #{$white-30};
  --white-40: #{$white-40};
  --white-50: #{$white-50};
  --white-60: #{$white-60};
  --white-70: #{$white-70};
  --white-80: #{$white-80};
  --white-90: #{$white-90};
  --white-100: #{$white};
  --white-smoke: #{$white-smoke};
}
