@mixin geolocation-details-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $font1: mat-color($custom-colors, font1);
  $font-accent: mat-color($custom-colors, font-accent);
  $background-color1: mat-color($custom-colors, background-color1);
  $profiler-accent: mat-color($custom-colors, profiler-accent);

  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .details-card {
    .details-card-content {
      color: $font1;
    }

    .mat-mdc-card-content {
      span {
        color: $secondary;
      }
    }

    label {
      color: $font1;
    }

    ::ng-deep {
      mat-card {
        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
        background-color: $background-color1;
      }
    }

    .details-label {
      .link {
        color: $accent !important;
      }
    }

    .multiselect {
      color: $font1;
    }
  }

  .export-single-geolocation {
    ​ span {
      color: $font-accent;
    }
  }

  .log-geolocation-details-info-button {
    border: solid 2px #979797 !important;
    background-color: #3f5c58;
  }

  .log-geolocation-details-info-button span {
    color: $background-color1 !important;
  }

  .geo-redirect-to-target,
  .geo-add-to-target {
    background: $profiler-accent;
    color: #fff;
  }
}
