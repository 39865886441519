@mixin profiler-dashboard-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $background-color: mat-color($custom-colors, background-color);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);

  .profiler {
    .sidenav {
      background-color: $background-color;
    }

    .location-list {
      .profiler-locate-item-button {
        display: flex;
      }
    }

    .profiler-locate-item-button {
      .profiler-locate-item-text {
        color: #2a2a2a;
        margin-right: 5px;
      }
    }
  }

  .live-data-btn {
    border: solid 2px green !important;
  }

  .profiler-dashboard-section-border {
    border: solid 1px #d6e8f4;
  }

  .profiler-dashboard-top-content {
    background-color: $background-color;
  }
}
