@import 'src/styles/_palette';

@mixin link-analysis-kl-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent: mat-color($custom-colors, accent);

  .link-analysis-container {
    border: solid 1px $profiler-secondary;
    background-color: $primary;

    .graph-filters-content {
      .link-analysis-sidebar {
        border-left: solid 1px $profiler-secondary;
        background-color: rgba(255, 255, 255, 0.85);

        .la-tabs {
          .mat-tab-label {
            color: $profiler-font;
            background-color: $primary;
          }

          .mat-tab-label-active {
            color: $profiler-accent;
            border-bottom: 1px solid $profiler-accent;
          }
        }

        .la-graph-btn {
          background-color: $profiler-accent;

          span {
            color: $primary;
          }
        }

        .la-custom-panel {
          background-color: $primary;
          color: $profiler-font;
          border: solid 1px $profiler-secondary;

          .la-custom-panel-header {
            height: 40px !important;
            background-color: $profiler-secondary;

            .la-custom-panel-subheader {
              color: #828ea0;
            }
          }

          // custom line checkmark to indicate some selection (but not all)
          .few-selected-checkmark {
            .mat-checkbox-inner-container {
              .mat-checkbox-background {
                border-bottom: 1px solid $profiler-font;
              }
            }
          }

          .mat-expansion-panel-body {
            .filters-separator {
              border-bottom: 1px solid $profiler-secondary;
            }
          }
        }

        .la-remove-all-filters {
          color: $profiler-font;
          background-color: $primary;
        }
      }

      .graph-legend {
        color: $profiler-font;
        background-color: $primary;
      }
    }

    .la-top-filters {
      border-bottom: solid 1px $profiler-secondary;
      color: #424852;
      background-color: $primary;
    }
  }

  .dark-theme {
    background-color: #1f1f1f;

    .la-top-filters {
      color: $primary;
      background-color: #1f1f1f;
    }

    .graph-filters-content {
      .maximize-btn,
      .fullscreen-btn,
      .minimize-timebar-btn,
      .theme-btn {
        background-color: #1f1f1f;

        .icon {
          color: $primary;
        }
      }

      .kl-graph {
        .graph-legend {
          color: $primary;
          background-color: #1f1f1f !important;
        }

        .entities-panel {
          background-color: #1f1f1f !important;

          .close {
            color: $primary;
          }

          .entity-item {
            color: $primary;
          }
        }
      }
    }
  }

  .loader-background {
    background-color: #fbfbfb;
  }

  .select-info-window {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.38);
    background-color: $primary;

    .text {
      margin-bottom: 10px;
      color: #6f6f7a;
    }
  }

  .relation-filter-slider {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $profiler-secondary;
    background-color: $primary;

    .slider-title {
      color: $profiler-font;
    }

    .slider-value-border {
      border: solid 1px $profiler-secondary;
    }

    .slider {
      .mat-slider-track-background,
      .mat-slider-thumb {
        background-color: $profiler-accent;
      }

      .mat-slider-track-fill {
        background-color: $profiler-secondary;
      }
    }
  }
  .context-menu {
    background-color: $primary;

    .option {
      border-bottom: 1px solid #e8e6e6;
    }

    .option:hover {
      background-color: $white-smoke;
    }
  }

  .disabled {
    color: #b6b6b6;
  }
}
