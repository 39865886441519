ngx-file-drop {
  .ngx-file-drop__drop-zone {
    border: none !important;
    height: 100% !important;
  }

  .ngx-file-drop__content {
    height: 100% !important;
  }
}
