@mixin profiler-media-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .profiler-media {
    background-color: $primary;
    .view-media {
      color: #008df6;
    }

    .view-text {
      color: #005cff;
    }
  }
}
