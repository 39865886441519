@mixin profiler-facebook-result-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  .communities {
    background-color: $primary;
    .relation-titles {
      color: #3c4144;
    }
  }
}
