@mixin blacklist-theme($theme) {
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);

  .blacklist {
    color: $font1;
  }

  .btn-blacklist {
    background-color: $accent;
    color: $primary;
  }
}
