@mixin profiler-email-results-dialog-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font-5: map-get($custom-colors, font-5);

  .email-results-dialog {
    table {
      .actions-cell {
        mat-icon {
          color: $font-5;
        }
      }
      .danger-icon {
        color: red;
      }

      .warning-icon {
        color: #ff9800;
      }
    }
  }
}
