@use '@angular/material' as mat;
// Import material theming functions
@import 'node_modules/@angular/material/_theming';
@import './component-themes.scss';
@import './theme-variables.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

$primary: mat.define-palette($mat-custom-colors, 100);
$warn: mat.define-palette($mat-red);

$basic-accent: mat.define-palette($mat-custom-colors, 300);
$geoloc-accent: mat.define-palette($mat-custom-colors, 200);
$white-accent: mat.define-palette($mat-custom-colors, 300);
$basic-background-color1: mat.define-palette($mat-custom-colors, 100);

// Basic theme
$basic-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $basic-accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
    custom-colors: $basic-custom-colors,
  )
);

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($basic-theme);

@include mat.all-component-themes($basic-theme);
// Include the theme styles for only specified components.
@include component-themes($basic-theme);

// ------------------ GeoLoc Theme ------------------
.geoloc-theme {
  $geoloc-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary,
        accent: $geoloc-accent,
        warn: $warn,
      ),
      typography: mat.define-typography-config(),
      density: 0,
      custom-colors: $geoloc-custom-colors,
    )
  );
  /* TODO(mdc-migration): Remove all-legacy-component-colors once all legacy components are migrated*/
  @include mat.all-legacy-component-colors($geoloc-theme);

  @include mat.all-component-colors($geoloc-theme);
  @include component-themes($geoloc-theme);
}
