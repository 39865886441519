@mixin pdf-settings-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);
  $border: mat-color($custom-colors, border);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .pdf-settings {
    button {
      background-color: $accent;
      color: $primary;
    }

    .pdf-title {
      color: $font1;
    }

    .labels {
      label {
        color: $font1;
      }
    }

    .inputs {
      input {
        background-color: $primary;
        border: 1px solid $border;
      }

      input[value] {
        color: $font1;
      }
    }
  }
}
