@mixin profiler-breadcrumbs-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background-color: mat-color($custom-colors, background-color);
  $grey: mat-color($custom-colors, grey);

  .breadcrumbs-wrapper {
    background-color: $background-color;
  }

  .breadcrumbs {
    color: $grey;
  }

  .breadcrumbs-target {
    color: #828282;
  }
}
