@mixin advanced-geolocation-query-confirmation-dialog-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $font1: mat-color($custom-colors, font1);
  $background: mat-color($custom-colors, background-color);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .advanced-geolocation-query-confirmation-dialog {
    .advanced-geolocation-query-title {
      color: $font1;
    }

    .advanced-geolocation-query-subtitle {
      color: $font1;
    }

    .advanced-geolocation-query-value {
      color: $font1;
    }
  }

  .advanced-geolocation-query-confirmation-dialog-actions {
    .advanced-geolocation-query-ok {
      background-color: #314a46;
      color: $primary;
    }

    .advanced-geolocation-query-cancel {
      background-color: $background;
      color: #878787;
      border: solid 1px #eae6e6;
    }
  }
}
