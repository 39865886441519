@mixin searchIntel-history-theme($theme) {
  .history-wrp {
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .history-block {
    .table-wrp {
      .mat-mdc-table {
        tr {
          border-bottom: 1px solid #d8e1e4;
        }
      }

      table {
        tr {
          border-bottom: 1px solid #ccc;

          td,
          th {
            &.find-col {
              span.find-txt {
                color: #d2d4d4;
              }
            }
          }
        }
      }
    }
  }

  .history-search-input {
    background-color: white;
    border: solid 1px #dddfe2 !important;

    .remove-btn {
      color: #b3b3b2;
    }
  }
}
