@mixin profiler-common-people-theme($theme) {
  $primary-palette: map-get($theme, primary);
  $custom-colors: map-get($theme, custom-colors);
  $primary: mat-color($primary-palette);
  $profiler-media: mat-color($custom-colors, profiler-media);

  .profiler-common-people {
    background-color: $primary;
  }

  .image-occurences {
    color: white;
    background-color: $profiler-media;
  }
}
