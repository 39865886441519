@mixin si-log-table-item-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $background: mat-color($custom-colors, background-color);
  $font-accent: mat-color($custom-colors, font-accent);
  $dark-accent: mat-color($custom-colors, dark-accent);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .conversation-item-even {
    background-color: #d8e1e4;
  }

  .conversation-item-odd {
    background-color: $background;
  }

  .conversation-item {
    color: $font-accent;
  }
}
