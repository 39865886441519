table {
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type,
  th.mat-mdc-header-cell:first-of-type {
    padding-left: 15px;
  }
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding: 15px;
  }
  tr.mat-mdc-footer-row,
  tr.mat-mdc-row {
    height: initial;
  }
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    font-size: 12px;
    line-height: 18px;
  }
  th.mat-mdc-header-cell {
    font-weight: bold;
    color: #000;
    padding: 15px;
  }
}
