@mixin profiler-target-delete-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $primary-palette: map-get($theme, primary);
  $warn-palette: map-get($theme, warn);
  $primary: mat-color($primary-palette);
  $warn: mat-color($warn-palette);

  .target-delete-dialog {
    .profiler-target-delete-cancel {
      border: solid 2px #d3d3d3;
      background-color: $primary;
      color: rgba(139, 139, 139, 0.84);
    }

    .profiler-target-delete-header {
      color: rgba(51, 50, 56, 0.84);
    }

    .profiler-target-delete {
      border: solid 1px #ebebeb;
      background-color: #ff0101;
      color: white;
    }

    .profiler-target-delete-separation {
      border-bottom: 1px solid rgba(6, 15, 22, 0.3);
    }

    .profiler-target-delete-first-row {
      color: rgba(51, 50, 56, 0.84);
    }

    .profiler-target-delete-second-row {
      color: #ff0101;
    }
  }
}
