@mixin link-analysis-top-filters-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $profiler-secondary: mat-color($custom-colors, profiler-secondary);
  $profiler-font: mat-color($custom-colors, profiler-font);
  $profiler-accent: mat-color($custom-colors, profiler-accent);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent: mat-color($custom-colors, accent);

  .link-analysis-container {
    .la-top-filters {
      .filter-item-border {
        border: solid 1px $profiler-secondary;
      }

      .toggle-la {
        color: $profiler-font;

        .mat-checked .mat-slide-toggle-bar {
          background-color: $profiler-accent !important;
        }
      }

      .la-top-filter-checkbox {
        .mat-checkbox-inner-container .mat-checkbox-frame {
          border-color: $profiler-secondary;
        }
      }
    }
  }

  // .dark-theme {
  //   .la-top-filters {
  //     .left-filters {
  //       .global-select {
  //         background-color: $primary !important;
  //       }
  //     }
  //   }
  // }

  .disabled {
    color: #b6b6b6;
  }

  .mutual-friends-slider {
    nouislider {
      ::ng-deep {
        .noUi-connect {
          background-color: #204cd3;
        }

        .noUi-horizontal {
          .noUi-handle {
            background-color: #204cd3;
            border: #204cd3;
          }
        }
      }
    }
  }
}
