.mat-mdc-menu-content {
  line-height: 16px;
}

.mat-mdc-card {
  box-shadow: none;

  .mat-mdc-card-content {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

mat-paginator {
  .mdc-text-field--outlined {
    padding: 0 4px;
  }
  .mat-mdc-paginator-range-actions {
    .mat-mdc-icon-button {
      .mat-mdc-button-touch-target {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.mat-mdc-select-panel .mat-mdc-option {
  height: 30px;
  min-height: unset;

  &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: var(--black);
    opacity: 0.12;
  }
}

.mat-mdc-select-panel {
  min-width: calc(100% + 32px);
}
