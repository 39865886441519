@mixin geofencing-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $secondary: mat-color($custom-colors, secondary);
  $font1: mat-color($custom-colors, font1);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);

  .geofencing-tabs {
    .places {
      ::ng-deep {
        mat-placeholder {
          color: $secondary;
        }
      }
    }

    .country {
      ::ng-deep {
        mat-placeholder,
        input {
          color: $secondary;
        }
      }
    }
  }
}
