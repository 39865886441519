@mixin aoi-query-table-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $dark-accent: mat-color($custom-colors, dark-accent);
  $font1: mat-color($custom-colors, font1);
  $background: mat-color($custom-colors, background-color);

  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $accent-palette: map-get($theme, accent);
  $accent: mat-color($accent-palette);

  .aoi-query-card {
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: $primary;

    .aoi-table {
      table {
        background-color: transparent !important;
      }
    }

    .mat-mdc-card-title {
      color: $font1;
    }

    .aoi-table {
      .mat-mdc-row:nth-child(even) {
        background-color: #ebe1dc;
      }

      .mat-mdc-row:nth-child(odd) {
        background-color: $background;
      }
    }

    .selected-aoi {
      background-color: $dark-accent !important;

      .text {
        color: $primary;
      }
    }
  }
}
