@mixin profiler-employment-theme($theme) {
  $custom-colors: map-get($theme, custom-colors);
  $primary-palette: map-get($theme, primary);
  $primary: mat-color($primary-palette);
  $profiler-accent: mat-color($custom-colors, profiler-accent);

  .profiler-employment {
    background-color: $primary;

    .view-employment {
      color: #008df6;
    }

    .view-text {
      color: $profiler-accent;
    }

    .organization-text {
      a {
        color: inherit;
      }
    }
  }
}
