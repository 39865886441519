.popup-wrp {
  box-sizing: border-box; // remove later
  max-width: 700px;
  width: 100%;
  position: fixed;
  top: 52%;
  left: 52%;
  transform: translate(-52%, -52%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  z-index: 101;
  .mat-icon {
    position: absolute;
    right: 15px;
    color: #ccc;
    cursor: pointer;
  }
  .head-title {
    color: rgba(51, 50, 56, 0.84);
    font-weight: inherit;
    margin: 0 0 15px 0;
  }
  .popup-data {
    padding: 30px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    align-items: initial;
    justify-content: flex-start;
    .img-wrp {
      max-width: 200px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .btn-wrp {
    justify-content: space-around;
    padding: 0px 80px;
    padding-top: 20px;
  }
}

.toast-container {
  pointer-events: auto;
}

.swal-confirmation-dialog {
  .swal2-header {
    padding: 0;
  }
  .swal2-title {
    font-size: 22px;
  }
  .swal2-icon.swal2-warning {
    border-color: #de4b32;
    color: #de4b32;
  }
  .swal2-icon {
    width: 50px;
    height: 50px;
    .swal2-icon-content {
      font-size: 38px;
    }
  }
  .swal2-styled.swal2-confirm,
  .swal2-styled.swal2-cancel {
    border-radius: 20px !important;
    background-color: #204cd3 !important;
    border: 0 !important;
    color: #fff !important;
  }
  .swal2-styled.swal2-cancel {
    background-color: rgb(221, 51, 51) !important;
  }
}
