@font-face {
  font-family: 'Intellectus';
  src: url('./Intellectus.eot?oagx1k');
  src: url('./Intellectus.eot?oagx1k#iefix') format('embedded-opentype'),
    url('./Intellectus.ttf?oagx1k') format('truetype'),
    url('./Intellectus.woff?oagx1k') format('woff'),
    url('./Intellectus.svg?oagx1k#Intellectus') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='gio-'],
[class*=' gio-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Intellectus' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gio-infinity:before {
  content: '\e981';
}
.gio-link:before {
  content: '\e97e';
}
.gio-hashtag:before {
  content: '\e97f';
}
.gio-inv-refresh-restart:before {
  content: '\e977';
}
.gio-inv-alert .path1:before {
  content: '\e978';
  color: rgb(255, 255, 255);
}
.gio-inv-alert .path2:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.gio-inv-check-mark:before {
  content: '\e97a';
}
.gio-inv-delete:before {
  content: '\e97b';
}
.gio-inv-link:before {
  content: '\e97c';
}
.gio-inv-pin:before {
  content: '\e980';
}
.gio-inv-loading:before {
  content: '\e97d';
}
.gio-inv_ta:before {
  content: '\e976';
  color: #767676;
}
.gio-home_icon:before {
  content: '\e975';
  color: #767676;
}
.gio-phone-device:before {
  content: '\e971';
}
.gio-tiktok .path1:before {
  content: '\e972';
  color: rgb(211, 211, 211);
}
.gio-tiktok .path2:before {
  content: '\e973';
  margin-left: -0.884765625em;
  color: rgb(0, 0, 0);
}
.gio-tiktok .path3:before {
  content: '\e974';
  margin-left: -0.884765625em;
  color: rgb(133, 133, 133);
  opacity: 0.87;
}
.gio-workplace:before {
  content: '\e91d';
}
.gio-cases:before {
  content: '\e91e';
}
.gio-email:before {
  content: '\e928';
}
.gio-facebook:before {
  content: '\e929';
}
.gio-groups:before {
  content: '\e957';
}
.gio-image-single:before {
  content: '\e962';
}
.gio-instagram:before {
  content: '\e963';
}
.gio-linkedin:before {
  content: '\e964';
}
.gio-location-pin .path1:before {
  content: '\e965';
  color: rgb(0, 0, 0);
}
.gio-location-pin .path2:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.gio-phone-location:before {
  content: '\e967';
}
.gio-phone-simple:before {
  content: '\e968';
}
.gio-post-single:before {
  content: '\e969';
}
.gio-sim:before {
  content: '\e96a';
}
.gio-skype:before {
  content: '\e96b';
}
.gio-telegram:before {
  content: '\e96c';
}
.gio-twitter:before {
  content: '\e96d';
}
.gio-university:before {
  content: '\e96e';
}
.gio-viber:before {
  content: '\e96f';
}
.gio-whatsapp:before {
  content: '\e970';
}
.gio-icon_heatmap:before {
  content: '\e95b';
}
.gio-icon_seen:before {
  content: '\e95c';
}
.gio-icon_timeline2:before {
  content: '\e95d';
}
.gio-pin_favorite .path1:before {
  content: '\e95e';
  color: rgb(0, 0, 0);
}
.gio-pin_favorite .path2:before {
  content: '\e95f';
  margin-left: -0.826171875em;
  color: rgb(255, 255, 255);
}
.gio-pin_predicted .path1:before {
  content: '\e960';
}
.gio-pin_predicted .path2:before {
  content: '\e961';
  margin-left: -0.826171875em;
  color: rgb(255, 255, 255);
}
.gio-close:before {
  content: '\e955';
}
.gio-select_all:before {
  content: '\e956';
}
.gio-selected:before {
  content: '\e958';
}
.gio-star:before {
  content: '\e959';
}
.gio-trusted:before {
  content: '\e95a';
}
.gio-calendar:before {
  content: '\e925';
}
.gio-copy:before {
  content: '\e926';
}
.gio-copy_paste:before {
  content: '\e927';
}
.gio-like:before {
  content: '\e92a';
}
.gio-map:before {
  content: '\e92b';
}
.gio-map_control_disable .path1:before {
  content: '\e92c';
  color: rgb(0, 0, 0);
}
.gio-map_control_disable .path2:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.gio-map_control_disable .path3:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.gio-map_control_enable .path1:before {
  content: '\e92f';
  color: rgb(255, 255, 255);
}
.gio-map_control_enable .path2:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.gio-map_control_enable .path3:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.gio-Person:before {
  content: '\e932';
}
.gio-phone:before {
  content: '\e933';
}
.gio-pin:before {
  content: '\e934';
}
.gio-pin_album .path1:before {
  content: '\e935';
  color: rgb(0, 0, 0);
}
.gio-pin_album .path2:before {
  content: '\e936';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path3:before {
  content: '\e937';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path4:before {
  content: '\e938';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path5:before {
  content: '\e939';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path6:before {
  content: '\e93a';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path7:before {
  content: '\e93b';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path8:before {
  content: '\e93c';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path9:before {
  content: '\e93d';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path10:before {
  content: '\e93e';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path11:before {
  content: '\e93f';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path12:before {
  content: '\e940';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path13:before {
  content: '\e941';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path14:before {
  content: '\e942';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_album .path15:before {
  content: '\e943';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_call_in .path1:before {
  content: '\e944';
  color: rgb(0, 0, 0);
}
.gio-pin_call_in .path2:before {
  content: '\e945';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_call_in .path3:before {
  content: '\e946';
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.gio-pin_call_in .path4:before {
  content: '\e947';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_call_out .path1:before {
  content: '\e948';
  color: rgb(0, 0, 0);
}
.gio-pin_call_out .path2:before {
  content: '\e949';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_call_out .path3:before {
  content: '\e94a';
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.gio-pin_call_out .path4:before {
  content: '\e94b';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_comment .path1:before {
  content: '\e94c';
  color: rgb(0, 0, 0);
}
.gio-pin_comment .path2:before {
  content: '\e94d';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path1:before {
  content: '\e94e';
  color: rgb(0, 0, 0);
}
.gio-pin_data .path2:before {
  content: '\e94f';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path3:before {
  content: '\e950';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path4:before {
  content: '\e951';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path5:before {
  content: '\e952';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path6:before {
  content: '\e953';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_data .path7:before {
  content: '\e954';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_sms_out .path1:before {
  content: '\e900';
  color: rgb(0, 0, 0);
}
.gio-pin_sms_out .path2:before {
  content: '\e901';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_sms_out .path3:before {
  content: '\e902';
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.gio-pin_sms_out .path4:before {
  content: '\e903';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_work .path1:before {
  content: '\e904';
  color: rgb(0, 0, 0);
}
.gio-pin_work .path2:before {
  content: '\e905';
  margin-left: -0.7939453125em;
  color: rgb(255, 255, 255);
}
.gio-pin_write .path1:before {
  content: '\e906';
  color: rgb(0, 0, 0);
}
.gio-pin_write .path2:before {
  content: '\e907';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_write .path3:before {
  content: '\e908';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_write .path4:before {
  content: '\e909';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-query:before {
  content: '\e90a';
}
.gio-requery:before {
  content: '\e90b';
}
.gio-social_network:before {
  content: '\e90c';
}
.gio-tag:before {
  content: '\e90d';
}
.gio-time:before {
  content: '\e90e';
}
.gio-time_reaload:before {
  content: '\e90f';
}
.gio-video:before {
  content: '\e910';
}
.gio-write:before {
  content: '\e911';
}
.gio-article:before {
  content: '\e912';
}
.gio-comment:before {
  content: '\e913';
}
.gio-pin_full:before {
  content: '\e914';
}
.gio-pin_home .path1:before {
  content: '\e915';
  color: rgb(0, 0, 0);
}
.gio-pin_home .path2:before {
  content: '\e916';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_home .path3:before {
  content: '\e917';
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.gio-pin_note .path1:before {
  content: '\e918';
  color: rgb(0, 0, 0);
}
.gio-pin_note .path2:before {
  content: '\e919';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_note .path3:before {
  content: '\e91a';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_note .path4:before {
  content: '\e91b';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_note .path5:before {
  content: '\e91c';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_query .path1:before {
  content: '\e91f';
  color: rgb(0, 0, 0);
}
.gio-pin_query .path2:before {
  content: '\e920';
  margin-left: -0.79296875em;
  color: rgb(255, 255, 255);
}
.gio-pin_sms_in .path1:before {
  content: '\e921';
  color: rgb(0, 0, 0);
}
.gio-pin_sms_in .path2:before {
  content: '\e922';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-pin_sms_in .path3:before {
  content: '\e923';
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.gio-pin_sms_in .path4:before {
  content: '\e924';
  margin-left: -0.9228515625em;
  color: rgb(255, 255, 255);
}
.gio-file:before {
  content: '\e976';
}
